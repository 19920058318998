import React, { useEffect, useState } from "react";
import AppPagination from "../components/pagination/app_pagination";
import ReusableTable, { Column } from "../components/table/hoverable_table_row";
import creditService, { CreditBalanceData, CreditRequestParams, CreditTransactionRequestOptions, CreditTxnData, ResponseWithMeta } from "../services/credit_service";
import { Router, useHref, useNavigate, useParams } from 'react-router-dom';
import { StyledContainer } from "../components/styled/styled_flex_container";
import { formatToCurrencyString } from "../helpers/number_formatter_helper";
import { convertISOStringtoFormattedDateTime } from "../helpers/date_time_helper";
import { Fab, IconButton, Paper, SpeedDial, SpeedDialAction, SpeedDialIcon, Stack, Tooltip } from "@mui/material";
import PaginatedTable from "../components/template/pagination_table_page";
import CreditTableFilter from "../components/table_filters/credit_table_filter";
import CreditAdjustmentDialog, { CreditAdjustmentDialogProps } from "../components/dialog/credit_adjustment_dialog";
import { JSX } from "react/jsx-runtime";
import { useDialog } from "../stores/providers/dialog_provider";
import { StyledOutlineButton, StyledTextButton } from "../styled/filled_button";
import PromptTypo from "../components/typography/typography_prompt";
import FormBuilder, { AppFormData } from "../components/form/form_builder";
import dayjs from "dayjs";
import { Add, ChevronLeft, Create, History, KeyboardArrowLeft } from "@mui/icons-material";
import { useAppSettings } from "../stores/context/app_setting_context";
import userService from "../services/user_service";
import { User } from "../services/auth_service";
import { tableContentConfig } from "../configs/constants";
import Spacer from "../components/spacing/spacer";
import { SwalCompleted } from "../components/dialog/swal_alert";

interface PaginatedTableProps {
    columnsProps?: Column[];
    defaultPerPage?: number;
    initialPage?: number;
}

interface TableRow {
    fullName: string;
    totalBalance: string;
    amount: string;
    id: string;
    ownerFirstname: string;
    ownerLastname: string;
    balance: number;
    remark?: string;
}

const columns: Column[] = [
    { label: 'เลขที่', field: "id" },
    { label: 'Owner Name', field: "fullName" },
    { label: 'TXN Date', field: "lastUpdateDatetime" },
    { label: 'TXN Type', field: "sourceTransactionId" },
    { label: 'จำนวนเงิน', field: "amount", ...tableContentConfig.commonPrimary, align: 'right' },
    { label: 'Balance', field: "balance", ...tableContentConfig.number },
]

const CreditBalanceDetailPage: React.FC<PaginatedTableProps> = ({
    columnsProps,
    defaultPerPage = 10,
    initialPage = 1,
}) => {
    const { openDialog, closeDialog } = useDialog();
    const [dialogId, setDialogId] = useState<number | null>();
    const [data, setData] = useState<any[]>([]);
    const [meta, setMeta] = useState<any>(null);
    const [owner, setOwner] = useState<any>(null);
    const [page, setPage] = useState<number>(initialPage);
    const [ownerCreditData, setOwnerCreditData] = useState<any[]>([]);
    const { id } = useParams();
    const { state } = useAppSettings();
    const navigate = useNavigate();

    useEffect(() => {
        fetchOwner();
        fetchCreditBalance();
    }, [])

    useEffect(() => {

        fetchTableData(page);
    }, [page]);

    const handleNavigateToAdjustmentListPage = () => {
        navigate(`/credit-txn/detail/${id}/adjustment-list`);
    }

    const fetchTableData = async (page: number) => {
        const payload: CreditTransactionRequestOptions = {
            page: page == 0 ? 1 : page ?? 1,
            perPage: 10,
            ownerId: id
        }
        const response = await creditService.listCreditTxn(payload);
        if (!!response) {
            const responseData = response?.data ?? [];
            const processedData = await _dataWithActions(responseData as CreditTxnData[]);
            setData(processedData as TableRow[]);
            setMeta(response.meta);
        }
        else { return null }
    };

    const fetchCreditBalance = async () => {
        const response = !!id && await creditService.listCreditsBalance({ owner: +id });
        if (!!response) {
            const responseData = response?.data ?? [];
            setOwnerCreditData(responseData);
        }
        else { return null }
    }

    const fetchOwner = async () => {
        const selectedUserInfo = await userService.getUserById(id ?? 0);
        if (!!selectedUserInfo) {
            setOwner(`${selectedUserInfo.firstName ?? ''} ${selectedUserInfo.lastName ?? ''}`)
        }
    }

    const _dataWithActions = async (data: CreditTxnData[]) => {
        if (!!data && data?.length == 0) return [];
        const dataWithAdditionalColumns = await Promise.all(data!.map(async (item) => {
            return {
                ...item,
                fullName: `${item.ownerFirstname}  ${item.ownerLastname}`,
                totalBalance: formatToCurrencyString(item?.balance ?? 0),
                amount: formatToCurrencyString(item?.amount ?? 0),
                lastUpdateDatetime: convertISOStringtoFormattedDateTime(item.txDate ?? item.lastUpdateDatetime ?? '')
            }
        }));

        return dataWithAdditionalColumns;
    }

    const fetchDataProps = async (payload?: CreditTransactionRequestOptions): Promise<ResponseWithMeta<CreditTxnData>> => {
        const params: CreditTransactionRequestOptions = {
            page: payload?.page || 1,
            perPage: payload?.perPage || 10,
            ...payload,
        };

        const response = await creditService.listCreditTxn(params);

        if (response === null) {
            return {
                data: [],
                meta: { totalCount: 0, page: +params.page, limit: +params.perPage },
            };
        }

        return response;
    };

    const handleAdjustment = async (formData: Record<string, string | number>) => {
        if (!!id) {
            const response = await creditService.creditAdjustRequest({
                ownerId: +id!,
                type: "ADJUST",
                amount: formData.amount,
                requestDatetime: dayjs().toISOString(),
                requestRemark: `${formData.reason}`,
                bankAccountId: "",
                transferDatetime: dayjs().toISOString(),
            });
            if (response) {
                SwalCompleted().then(() => navigate('/credit-approval/adjustments'))
            }
        }
    }

    function handleOnOpenAdjustDialog() {
        const dialogId = openDialog(
            <CreditDetailAdjustmentDialog onSubmit={(data) => {
                data && handleAdjustment(data)
            }} />);
        setDialogId(dialogId);
    }
    return (
        <StyledContainer>
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} sx={{}}>
                <StyledTextButton onClick={() => navigate(-1)}>
                    <KeyboardArrowLeft />
                    <Spacer size="xs" />
                    <PromptTypo>
                        กลับสู่หน้าหลัก
                    </PromptTypo>
                </StyledTextButton>
                <PromptTypo variant="h5">จัดการเครดิต : {owner}</PromptTypo>
                <StyledTextButton onClick={handleOnOpenAdjustDialog}>
                    <Add />
                    <Spacer size="xs" />
                    <PromptTypo>
                        จัดการเครดิต
                    </PromptTypo>
                </StyledTextButton>
            </Stack>
            <ReusableTable columns={columns ?? []} data={data} />
            {meta && (
                <AppPagination
                    page={meta.page || page}
                    count={meta.totalPages}
                    onPageChange={(page) => { setPage(page) }}
                />

            )}
        </StyledContainer>

    );
};

export default CreditBalanceDetailPage;

const formFields: AppFormData[] = [
    {
        name: 'type', value: 'ADJUST', required: true, placeholder: 'ประเภท', label: 'ประเภท', inputType: 'select',
        choices: [
            { value: 'ADJUST', label: 'Adjust' },
        ],
        textFieldProps: { type: 'number', name: '', autoComplete: '', "aria-readonly": true }
    },
    {
        name: 'amount', value: '', required: true, placeholder: 'จำนวน', label: 'จำนวน',
        textFieldProps: { type: 'number', name: '', autoComplete: '' }
    },
];
const CreditDetailAdjustmentDialog: React.FC<CreditAdjustmentDialogProps> = ({ item, onSubmit }) => {
    const { closeDialog } = useDialog();
    const handleFormSubmit = (data: Record<string, string | number>) => {
        onSubmit?.(data);
    };
    return (
        <FormBuilder
            formTitle="จัดการเครดิต"
            onCancel={() => closeDialog()}
            containerSize={560}
            fields={formFields}
            onSubmit={(data) => {
                closeDialog();
                handleFormSubmit(data);
            }} />
    );
}