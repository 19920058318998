import { BetType, LotteryNumberPosition } from "./constants";
import { GameWinningNumbers } from "./dto/GameWinningNumbers";
import { LotteryProcessingStrategy } from "./dto/LotteryProcessingStrategy";
import { LotteryTicketAtomNumberDto } from "./dto/LotteryTicketAtomNumberDto";
import { LotteryTicketNumberDto } from "./dto/LotteryTicketNumberDto";
import { PriceRateDto } from "./dto/PriceRateDto";
import { SplittedLotteryValue } from "./dto/SplittedLotteryValue";

export function getCombinations(input: string, length: number): string[] {
    const results: string[] = [];

    function helper(start: number, combination: string[]): void {
        if (combination.length === length) {
            results.push(combination.join(''));
            return;
        }

        for (let i = start; i < input.length; i++) {
            helper(i + 1, [...combination, input[i]]);
        }
    }

    helper(0, []);
    return results;
}

export function getPermutations(str: string): string[] {
    if (str.length <= 1) {
        return [str];
    }

    const permutations: string[] = [];
    for (let i = 0; i < str.length; i++) {
        const char = str[i];
        const remainingChars = str.slice(0, i) + str.slice(i + 1);
        for (const perm of getPermutations(remainingChars)) {
            permutations.push(char + perm);
        }
    }
    return permutations;
}

export function getPermutationsWithLength(input: string, length: number): string[] {
    // Base cases
    if (length === 1) {
        return input.split('');
    }

    if (length === input.length) {
        return getPermutations(input);
    }

    const results: string[] = [];

    function helper(prefix: string, chars: string, len: number): void {
        if (len === 0) {
            results.push(prefix);
            return;
        }

        for (let i = 0; i < chars.length; i++) {
            const newPrefix = prefix + chars[i];
            const remainingChars = chars.slice(0, i) + chars.slice(i + 1);
            helper(newPrefix, remainingChars, len - 1);
        }
    }

    helper('', input, length);
    return results;
}

export function extractLotteryValue(row: LotteryTicketNumberDto): SplittedLotteryValue {
    const valueRaw = row.valueRaw; // ช่องมูลค่า
    const isNineteenGame = valueRaw.startsWith("19*"); // ดูว่าเล่น 19 หางไหม

    // Initialize the result object with defaults
    let result: SplittedLotteryValue = {
        straight: null,
        permute: null,
        lower: null
    }

    if (isNineteenGame) {
        // แยกเฉพาะเคส 19 หาง
        const nineteenValue = valueRaw.replace('19*', '');
        result.straight = nineteenValue || null; // Straight value
        result.permute = null; // Permutation value
        result.lower = null; // Lower value
    } else {
        // Match straight, permute, and lower parts using regex
        const regex = /(?:(\d+))?(?:\*(\d+))?(?:\/(\d+))?/;
        const matches = valueRaw.match(regex);

        // Assign values based on regex capturing groups
        if (matches !== null) {
            result.straight = matches[1] || null; // Straight value
            result.permute = matches[2] || null; // Permutation value
            result.lower = matches[3] || null; // Lower value
        }

    }
    // console.log(`Number: ${numberRaw}, Value: ${valueRaw}, Straight: ${result.straight}, Permutate: ${result.permute}, Lower: ${result.lower}`)
    return result;
}

export function determineBetType(numberLength: number, position: string): BetType | null {
    let betType = null;

    if (numberLength === 3 && position === LotteryNumberPosition.UPPER) {
        betType = BetType.THREE_UPPER_STRAIGHT;
    }

    if (numberLength === 3 && position === LotteryNumberPosition.LOWER) {
        betType = BetType.THREE_LOWER_STRAIGHT;
    }

    if (numberLength === 2 && position === LotteryNumberPosition.UPPER) {
        betType = BetType.TWO_UPPER_STRAIGHT;
    }

    if (numberLength === 2 && position === LotteryNumberPosition.LOWER) {
        betType = BetType.TWO_LOWER_STRAIGHT;
    }

    return betType;
}

export function determineBetTypeShuffle(numberLength: number): BetType | null {
    let betType = null;
    if (numberLength === 3) betType = BetType.THREE_SHUFFLE;
    if (numberLength === 2) betType = BetType.TWO_SHUFFLE;
    return betType;
}

// Utility functions to handle conditions
export const uniqueSet = (arr: Iterable<unknown> | null | undefined) => Array.from(new Set(arr));

export const isTwentyGame = (lotteryTicketNumber: LotteryTicketNumberDto) => lotteryTicketNumber.numberRaw.endsWith("**");

export const isAllSameKind = (lotteryTicketNumber: LotteryTicketNumberDto) => lotteryTicketNumber.numberRaw === "---" || lotteryTicketNumber.numberRaw === "--";

export const isRunningNumber = (lotteryTicketNumber: LotteryTicketNumberDto) => {
    const numberRaw = lotteryTicketNumber.numberRaw;
    const numberOnlyDigit = numberRaw.replace(/\*|-/g, '');
    return numberOnlyDigit.length === 1 && !isAllSameKind(lotteryTicketNumber);
}

export const isWinNumber = (lotteryTicketNumber: LotteryTicketNumberDto) => {
    const numberRaw = lotteryTicketNumber.numberRaw;
    const numberOnlyDigit = numberRaw.replace(/\*|-/g, '');
    return numberOnlyDigit.length > 3 && numberRaw.endsWith("*");
}

export const isFloated = (lotteryTicketNumber: LotteryTicketNumberDto) => lotteryTicketNumber.numberRaw.endsWith("-");

export const generateUpperLower = (lotteryTicketNumber: LotteryTicketNumberDto, result: LotteryTicketAtomNumberDto[]) => {
    // ใส่ positionRaw => position และแตก บ+ล เป็น บ และ ล
    const valueRaw = lotteryTicketNumber.valueRaw;
    const extractedValue = extractLotteryValue(lotteryTicketNumber);

    const isIncludeLower = valueRaw.includes("/");
    const isIncludeBoth = result.some(row => row.position === LotteryNumberPosition.BOTH);
    if (isIncludeBoth || isIncludeLower) {
        const bothUpperLower = result.filter(row => row.position === LotteryNumberPosition.BOTH);
        const upperOnly = bothUpperLower.map(row => ({
            ...row,
            position: LotteryNumberPosition.UPPER,
            betType: row.betType || determineBetType(row.number.length, LotteryNumberPosition.UPPER),
        }));
        const lowerOnly = bothUpperLower.map(row => ({
            ...row,
            value: extractedValue?.lower || row.value,
            position: LotteryNumberPosition.LOWER,
            betType: row.betType || determineBetType(row.number.length, LotteryNumberPosition.LOWER),
        }));
        result = [...result, ...upperOnly, ...lowerOnly].filter(row => row.position !== LotteryNumberPosition.BOTH);
    }
    return result;
}

export const genericLotteryTicketNumberProcessor = (strategy: LotteryProcessingStrategy, lotteryTicketNumber: LotteryTicketNumberDto) => {
    let result = strategy.calculateAppliedNumbers(lotteryTicketNumber);
    const totalAmount = calculateTotalAmount(lotteryTicketNumber, result);
    let messages = [];
    let isValid = true;

    // เอาเคสแปลก ๆ ออก: โต๊ด ล่าง
    const isIncludeThreeLowerShuffle = result.some((row: any) => row.position === LotteryNumberPosition.LOWER && (row.betType === BetType.THREE_SHUFFLE || row.betType === BetType.TWO_SHUFFLE));
    if (isIncludeThreeLowerShuffle) {
        result = result.filter((row: any) => !(row.position === LotteryNumberPosition.LOWER && (row.betType === BetType.THREE_SHUFFLE || row.betType === BetType.TWO_SHUFFLE)));
        messages.push("ไม่รองรับโต๊ดตัวล่าง");
    }

    // คำนวณค่า isValid
    const isAbleToCalculateValueForAll = result.every((row: any) => row.value !== "-1");
    if (!isAbleToCalculateValueForAll) {
        messages.push("ไม่สามารถคำนวณการแทงของบางตัวได้");
    }
    const isLotteryTicketConditionValid = strategy.isLotteryTicketConditionValid(lotteryTicketNumber);
    isValid = !isIncludeThreeLowerShuffle && isAbleToCalculateValueForAll && isValid && isLotteryTicketConditionValid;

    return {
        appliedNumbers: result,
        totalAmount: totalAmount,
        messages: messages,
        isValid: isValid
    }
}

export const calculateTotalAmount = (lotteryTicketNumber: LotteryTicketNumberDto, result: LotteryTicketAtomNumberDto[]) => {
    // -- ดึงช่องออกมาเพื่อใช้ต่อไป
    const extractedValue = extractLotteryValue(lotteryTicketNumber); // คำนวณมูลค่า
    // หา totalAmount ของเลขนี้
    const totalAmountExcludeShuffle = result
        .filter(row => row.betType !== BetType.THREE_SHUFFLE && row.betType !== BetType.TWO_SHUFFLE)
        .reduce((acc, row) => acc + Number(row.value), 0)
    const totalAmountOnlyShuffle = result.some(row => row.betType === BetType.THREE_SHUFFLE || row.betType === BetType.TWO_SHUFFLE) ? Number(extractedValue?.permute || 0) : 0;
    return totalAmountExcludeShuffle + totalAmountOnlyShuffle;
}

export const genericLotteryTicketNumberPrizeProcessor = (strategy: LotteryProcessingStrategy, lotteryTicketNumber: LotteryTicketNumberDto, gameWinningNumber: GameWinningNumbers, priceRate: PriceRateDto) => {
    let processedLotteryTicketNumber = strategy.processLotteryTicketNumber(strategy, lotteryTicketNumber);

    if (!strategy) throw new Error("Invalid lottery processing strategy");
    if (!lotteryTicketNumber) throw new Error("Invalid lottery ticket number");
    if (!gameWinningNumber) throw new Error("Invalid game winning number");
    if (!processedLotteryTicketNumber.isValid) throw new Error("Invalid lottery ticket number");

    const processedPrizeResult = processedLotteryTicketNumber.appliedNumbers.map(number => {
        if (!strategy.calculateNumberPrize) throw new Error("Invalid lottery processing strategy");
        const calculateResult = strategy.calculateNumberPrize(number, gameWinningNumber, priceRate);
        console.log(number.number, number.betType, number.position, number.value, " = ", JSON.stringify(calculateResult));
        return calculateResult;
    });

    return {
        winNumbers: processedPrizeResult,
        totalPrize: processedPrizeResult.reduce((prev: any, curr: any) => prev + (curr?.winPrize || 0), 0)
    }
}