import { Box, Paper, Table, TableBody, TableContainer, TableHead, useTheme } from "@mui/material";
import React, { ReactNode } from "react";
import PromptTypo from "../../typography/typography_prompt";
import { Column } from "../hoverable_table_row";

// Define the RowData type
interface RowData {
    id: number;
    type: string;
    number: string;
    amount: string;
    isValid?: boolean | null
}

interface CustomColumn extends Column {
    field: keyof RowData;
}

interface OrderTableRowProps {
    rows: RowData[];
    columns: CustomColumn[];
    readonly?: boolean;
}

const OrderTableRow: React.FC<OrderTableRowProps> = ({ rows, columns }) => {
    const muiTheme = useTheme();
    // Render the table header
    const renderTableHead = () => (
        <TableHead>
            <tr>
                {columns.map((column) => (
                    <th key={column.field}>{column.label}</th>
                ))}
            </tr>
        </TableHead>
    );

    const renderTableBody = () => (
        <TableBody>
            {rows.map((row) => (
                <tr key={row.id} >
                    {columns.map((column) => (
                        <td key={column.field} style={{ backgroundColor: (row.isValid === false) ? `${muiTheme.palette.error.main}50` : 'transparent' }}>
                            {/* Pass the entire row to render */}
                            {column.render
                                ? column.render(row[column.field], row) // Pass both value and row to render
                                : (
                                    <Box
                                        sx={{
                                            width: '100%',
                                            height: '100%',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            display: 'flex',
                                        }}
                                    >
                                        <PromptTypo>-</PromptTypo>
                                    </Box>
                                )}
                        </td>
                    ))}
                </tr>
            ))}
        </TableBody>
    );

    return (
        <div>
            <TableContainer sx={{ padding: '16px 8px' }} component={Paper}>
                <Table border={1} cellPadding="5" cellSpacing="0">
                    {renderTableHead()}
                    {renderTableBody()}
                </Table>
            </TableContainer>
        </div>
    );
};

export default OrderTableRow;
