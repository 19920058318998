export const USER_STATUS = {
    "ACTIVE": "ACTIVE",
    "DISABLED": "DISABLED",
    "DELETED": "DELETED",
}

export const USER_ROLE = {
    "SUPER_ADMIN": "SA",
    "OWNER": "W",
    "STAFF": "S",
    "AGENT": "A"
}

export const CREDIT_REQUEST_STATUS = {
    REQUEST: "REQUEST",
    APPROVED: "APPROVED",
    REJECTED: "REJECTED",
}

export const CREDIT_TX_SOURCE = {
    ADJUST: "ADJUST",
    TOPUP: "TOPUP"
}

export const CREDIT_REQUEST_TYPE = {
    ADJUST: "ADJUST",
    TOPUP: "TOPUP"
}

export enum TicketStatus {
    RECEIVE = "receive",
    RETURN = "return",
}

export enum BetType {
    THREE_UPPER_STRAIGHT = "threeUpperStraight",
    THREE_SHUFFLE = "threeShuffle",
    TWO_UPPER_STRAIGHT = "twoUpperStraight",
    TWO_SHUFFLE = "twoShuffle",
    UPPER_RUNNING = "upperRunning",
    THREE_LOWER_STRAIGHT = "threeLowerStraight",
    TWO_LOWER_STRAIGHT = "twoLowerStraight",
    LOWER_RUNNING = "lowerRunning",
    FOUR_FLOATING = "fourFloating",
    FIVE_FLOATING = "fiveFloating"
}

export enum LotteryNumberPosition {
    "UPPER" = "บ",
    "LOWER" = "ล",
    "BOTH" = "บ+ล",
}