import { LotteryNumberPosition, BetType } from "./constants";
import { LotteryTicketAtomNumberDto } from "./dto/LotteryTicketAtomNumberDto";
import { LotteryTicketDto } from "./dto/LotteryTicketDto";
import { LotteryTicketNumberDto } from "./dto/LotteryTicketNumberDto";
import {
    determineBetType, determineBetTypeShuffle,
    extractLotteryValue,
    getCombinations,
    getPermutations,
    getPermutationsWithLength
} from "./lottery-number-utils";
import { toLotteryTicketAtomNumberDto } from "./mapper";

/**
 * Processes a single lottery ticket number.
 * It might mark the ticket number as valid or invalid based on some logic.
 *
 * @param lotteryTicketNumber - The lottery ticket number to process.
 * @returns The processed LotteryTicketNumber with updated validation status.
 */
export function processLotteryTicketNumber(lotteryTicketNumber: LotteryTicketNumberDto): LotteryTicketNumberDto {
    // -- ตัวแปรเอาไว้คืนค่าภายหลัง
    let isValid = true;
    let messages = [];

    // -- ดึงช่องออกมาเพื่อใช้ต่อไป
    const numberRaw = lotteryTicketNumber.numberRaw; // ช่องตัวเลข
    const valueRaw = lotteryTicketNumber.valueRaw; // ช่องมูลค่า
    const extractedValue = extractLotteryValue(lotteryTicketNumber); // คำนวณมูลค่า

    // -- ตรวจสอบเงื่อนไขเบื้องต้น
    const isTwentyGame = numberRaw.endsWith("**"); // ดูว่าเล่น 19 หางไหม
    const isNumberPermutate = numberRaw.includes("*"); // ดูช่องตัวเลขว่าต้องกลับเลขไหม
    const isValueNeedPermutations = valueRaw.includes("*"); // ดูช่องมูลค่าว่าต้องกลับเลขไหม
    const numberOnlyDigit = numberRaw.replace(/\*|-/g, ''); // เคลียร์ช่องตัวเลขเอาสัญลักษณ์ออก
    const isIncludeLower = valueRaw.includes("/"); // ซื้อล่างด้วย
    const isThreeDigit = numberOnlyDigit.length === 3; // ตรวจว่าเลขสามตัวหรือไม่
    const isMoreThanThreeDigit = numberOnlyDigit.length > 3; // ตรวจสอบว่าเลขเกินสามตัวหรือไม่ (เคสเลขวิน)
    const shouldIncludeStraight = isThreeDigit && !!extractedValue?.straight && isValueNeedPermutations; // สำหรับเคส 3 ตัวตรงและโต๊ดบน
    const isPermutateNumber = isNumberPermutate || isValueNeedPermutations; // ตรวจว่าต้องกลับเลขหรือไม่ จากทั้งสองฟิลด์
    const isAllSameKind = numberRaw === "---" || numberRaw === "--";
    const isFloated = numberRaw.endsWith("-");
    const isWinNumber = isMoreThanThreeDigit && numberRaw.endsWith("*"); // กรณีเลขวิน ต้องมีมากกว่าสามตัว และต้องจบด้วย *
    const numberLength = numberOnlyDigit.length;
    const isRunningNumber = numberLength === 1 && !isAllSameKind;
    const isTwoShuffle = valueRaw.startsWith("*") && numberLength === 2;

    // -- ฟังก์ชันเร็ว ๆ
    const uniqueSet = (arr: Iterable<unknown> | null | undefined) => Array.from(new Set(arr));

    // -- เริ่มการคำนวณค่า
    let result: LotteryTicketAtomNumberDto[] = [];

    if (isTwentyGame) {
        // กรณี 20 หาง
        const twentyVariations = Array.from({ length: 10 }, (_, i) => [`${numberOnlyDigit}${i}`, `${i}${numberOnlyDigit}`]).flat();
        result = twentyVariations.map(number => {
            let betType = null;
            if (lotteryTicketNumber.positionRaw === LotteryNumberPosition.UPPER) betType = BetType.TWO_UPPER_STRAIGHT;
            if (lotteryTicketNumber.positionRaw === LotteryNumberPosition.LOWER) betType = BetType.TWO_LOWER_STRAIGHT;
            const atomicNumber = toLotteryTicketAtomNumberDto(
                betType,
                String(number),
                lotteryTicketNumber.positionRaw,
                extractedValue.straight || "-1"
            );
            return atomicNumber;
        });

        // ถ้าหากว่าแทงบนล่าง ก็คือ ไม่มี ให้ invalid
        isValid = !(lotteryTicketNumber.positionRaw === LotteryNumberPosition.BOTH) && isValid;
    } else if (isRunningNumber) {
        let betType = null;
        if (lotteryTicketNumber.positionRaw === LotteryNumberPosition.UPPER) betType = BetType.UPPER_RUNNING;
        if (lotteryTicketNumber.positionRaw === LotteryNumberPosition.LOWER) betType = BetType.LOWER_RUNNING;
        result = [
            toLotteryTicketAtomNumberDto(
                betType,
                numberOnlyDigit,
                lotteryTicketNumber.positionRaw,
                extractedValue.straight || "-1"
            )
        ];
    } else if (isWinNumber) {
        // แตกรอบแรก
        const winNumberVariationsFirstPhase = uniqueSet(getCombinations(numberOnlyDigit, 3));
        // แตกรอบสอง
        const winNumberVariationsSecondPhase = winNumberVariationsFirstPhase.map(number => getPermutations(number as string)).flat();
        result = winNumberVariationsSecondPhase.map(number => {
            const atomicNumber = toLotteryTicketAtomNumberDto(
                BetType.THREE_UPPER_STRAIGHT,
                String(number),
                lotteryTicketNumber.positionRaw,
                extractedValue.straight || "-1"
            );
            return atomicNumber;
        });

        // ต้องไม่มีเลขซ้ำ
        const hasUniqueCharacters = new Set(numberOnlyDigit).size === numberOnlyDigit.length;
        isValid = isValid && hasUniqueCharacters;
        // ต้องไม่มีแทงล่างหรือบน+ล่าง
        isValid = !(lotteryTicketNumber.positionRaw === LotteryNumberPosition.LOWER) &&
            !(lotteryTicketNumber.positionRaw === LotteryNumberPosition.BOTH) && isValid;
    } else if (isFloated && !isAllSameKind) {
        // กรณีเลขลอยแพ
        const floatLength = numberOnlyDigit.length;
        let betType = null;
        if (floatLength == 4) betType = BetType.FOUR_FLOATING;
        if (floatLength == 5) betType = BetType.FIVE_FLOATING;
        result = [toLotteryTicketAtomNumberDto(
            betType,
            numberOnlyDigit,
            lotteryTicketNumber.positionRaw,
            extractedValue.straight || "-1"
        )];
    } else if (isAllSameKind) {
        // กรณีเลขตอง
        result = Array.from({ length: 10 }, (_, i) => {
            const number = numberRaw.replace(/-/g, i.toString()); // Use same number with repeated length
            const atomicNumber = toLotteryTicketAtomNumberDto(
                determineBetType(numberRaw.length, lotteryTicketNumber.positionRaw),
                String(number),
                lotteryTicketNumber.positionRaw,
                extractedValue?.permute || extractedValue?.straight || "-1"
            );
            return atomicNumber;
        });
    } else {
        // กรณีทั่วไป สามตัว สองตัว โต๊ด ฯลฯ
        if (shouldIncludeStraight) {
            // กรณีเลข 3 ตัวตรงและโต๊ด จะต้องเพิ่มเลขตรงไปด้วย
            const straightNumber = toLotteryTicketAtomNumberDto(
                determineBetType(numberLength, lotteryTicketNumber.positionRaw),
                numberOnlyDigit,
                lotteryTicketNumber.positionRaw,
                extractedValue.straight || "-1"
            );
            result.push(straightNumber);
        }

        if (isPermutateNumber) {
            // กรณีต้องกลับเลข
            const permutations = uniqueSet(getPermutations(numberOnlyDigit));
            if (permutations.length === 2) {
                // ถ้าเป็นเลขสองตัวแล้วต้องกลับ ตัวนึงใช้ค่าตรง ตัวนึงใช้ค่ากลับ
                const straightNumber = toLotteryTicketAtomNumberDto(
                    isTwoShuffle ? BetType.TWO_SHUFFLE : determineBetType(numberLength, lotteryTicketNumber.positionRaw),
                    String(permutations[0]),
                    lotteryTicketNumber.positionRaw,
                    (isTwoShuffle ? extractedValue.permute : extractedValue.straight) || "-1"
                );

                const reverseNumber = toLotteryTicketAtomNumberDto(
                    isTwoShuffle ? BetType.TWO_SHUFFLE : determineBetType(numberLength, lotteryTicketNumber.positionRaw),
                    String(permutations[1]),
                    lotteryTicketNumber.positionRaw,
                    extractedValue.permute || "-1"
                );

                result.push(straightNumber, reverseNumber);
            } else {
                // กรณีเลขกลับทั่วไป
                result = [
                    ...result,
                    ...permutations.map(number => {
                        const lotteryNumber = toLotteryTicketAtomNumberDto(
                            isNumberPermutate ? determineBetType((number as string).length, lotteryTicketNumber.positionRaw) : determineBetTypeShuffle(numberLength),
                            String(number),
                            lotteryTicketNumber.positionRaw,
                            extractedValue.permute || extractedValue.straight
                        );
                        return lotteryNumber;
                    })
                ];
            }
        } else {
            // ถ้าไม่ซื้อเฉพาะเลขโต๊ด ให้เพิ่มเลขลงไปตัวเดียว
            let betType = determineBetType(numberLength, lotteryTicketNumber.positionRaw);
            const lotteryNumber = toLotteryTicketAtomNumberDto(
                betType,
                String(numberOnlyDigit),
                lotteryTicketNumber.positionRaw,
                extractedValue.straight
            );
            result.push(lotteryNumber);
        }
    }

    // ใส่ positionRaw => position และแตก บ+ล เป็น บ และ ล
    const isIncludeBoth = result.some(row => row.position === LotteryNumberPosition.BOTH);
    if (isIncludeBoth || isIncludeLower) {
        const bothUpperLower = result.filter(row => row.position === LotteryNumberPosition.BOTH);
        const upperOnly = bothUpperLower.map(row => ({
            ...row,
            position: LotteryNumberPosition.UPPER,
            betType: row.betType || determineBetType(row.number.length, LotteryNumberPosition.UPPER),
        }));
        const lowerOnly = bothUpperLower.map(row => ({
            ...row,
            value: extractedValue?.lower || row.value,
            position: LotteryNumberPosition.LOWER,
            betType: row.betType || determineBetType(row.number.length, LotteryNumberPosition.LOWER),
        }));
        result = [...result, ...upperOnly, ...lowerOnly].filter(row => row.position !== LotteryNumberPosition.BOTH);
    }

    // เอาเคสแปลก ๆ ออก: โต๊ด ล่าง
    const isIncludeThreeLowerShuffle = result.some(row => row.position === LotteryNumberPosition.LOWER && (row.betType === BetType.THREE_SHUFFLE || row.betType === BetType.TWO_SHUFFLE));
    if (isIncludeThreeLowerShuffle) {
        result = result.filter(row => !(row.position === LotteryNumberPosition.LOWER && (row.betType === BetType.THREE_SHUFFLE || row.betType === BetType.TWO_SHUFFLE)));
        messages.push("ไม่รองรับโต๊ดตัวล่าง");
    }

    // คำนวณค่า isValid
    const isAbleToCalculateValueForAll = result.every(row => row.value !== "-1");
    if (!isAbleToCalculateValueForAll) {
        messages.push("ไม่สามารถคำนวณการแทงของบางตัวได้");
    }
    isValid = !isIncludeThreeLowerShuffle && isAbleToCalculateValueForAll && isValid;

    // หา totalAmount ของเลขนี้
    const isEdited = !!lotteryTicketNumber.betTypeEditedRaw && !!lotteryTicketNumber.positionEditedRaw && !!lotteryTicketNumber.numberEditedRaw && !!lotteryTicketNumber.valueEditedRaw;
    const totalAmountExcludeShuffle = result
        .filter(row => row.betType !== BetType.THREE_SHUFFLE && row.betType !== BetType.TWO_SHUFFLE)
        .reduce((acc, row) => acc + Number(row.value), 0)
    const totalAmountOnlyShuffle = result.some(row => row.betType === BetType.THREE_SHUFFLE || row.betType === BetType.TWO_SHUFFLE) ? Number(extractedValue?.permute || 0) : 0;
    const totalAmount = totalAmountExcludeShuffle + totalAmountOnlyShuffle;

    // ตรวจสอบความถูกต้อง
    // ตรวจสอบว่ามี _appliedNumbers ที่ไม่มี betType หรือไม่
    if (result.some(row => row.betType === null)) {
        console.log(result);
    }

    return {
        ...lotteryTicketNumber,
        _isEdited: isEdited,
        _isValid: isValid,
        _betTypeRaw: null,
        _betTypeEditedRaw: null,
        _appliedNumbers: result,
        _totalAmount: totalAmount,
        _messages: messages
    }
}

/**
 * Processes a lottery ticket by validating each ticket number
 * and determining the status of the ticket.
 *
 * @param lotteryTicket - The lottery ticket to process.
 * @returns The processed LotteryTicket with updated status.
 */
export function processLotteryTicket(lotteryTicket: LotteryTicketDto): LotteryTicketDto {
    const processedNumbers = lotteryTicket.numbers.map(processLotteryTicketNumber);

    // Example rule: If all numbers are valid, the ticket is "processed"; otherwise, "invalid".
    const isTicketValid = processedNumbers.every((number) => number._isValid);
    return {
        ...lotteryTicket,
        numbers: processedNumbers,
    };
}