export interface PaginationRequestParams<T> {
    page?: number;
    perPage?: number;
    [key: string]: T | any;
}

export interface ResponseMetaData {
    totalPages?: number;
    totalCount?: number;
    page?: number;
    limit?: number;
    perPage?: number;
}

export const defaultPaginationParams: PaginationRequestParams<{}> = {
    page: 1,
    perPage: 10,
};
export const defaultResponseMetaData: ResponseMetaData = {
    page: 1,
    totalPages: 1
};

export interface INumberPrice {
    id: number;
    agentId: number;
    agentCode: string;
    agentFirstname: string;
    agentLastname: string;
    threeUpperStraightPrice: number;
    threeUpperStraightPercent: number;
    threeShufflePrice: number;
    threeShufflePercent: number;
    twoUpperStraightPrice: number;
    twoUpperStraightPercent: number;
    twoShufflePrice: number;
    twoShufflePercent: number;
    upperRunningPrice: number;
    upperRunningPercent: number;
    threeLowerStraightPrice: number;
    threeLowerStraightPercent: number;
    twoLowerStraightPrice: number;
    twoLowerStraightPercent: number;
    lowerRunningPrice: number;
    lowerRunningPercent: number;
    fourFloatingPrice: number;
    fourFloatingPercent: number;
    fiveFloatingPrice: number;
    fiveFloatingPercent: number;
}

export interface INumberDetail {
    rowIndex: number;
    positionRaw: string;
    numberRaw: string;
    valueRaw: string;
    positionEditedRaw: string;
    numberEditedRaw: string;
    valueEditedRaw: string;
}

export interface ITicketSequencialNumber {
    nextTicketId: number;
    previousTicketId: number;
}

export interface ITicketData {
    id: number;
    sequence: string;
    priceRates: INumberPrice;
    ticketStatus: string;
    betAmount: number;
    receiveAmount: number;
    deductAmount: number;
    winAmount: number;
    numbers: INumberDetail[];
    links: ITicketSequencialNumber;
}

