// EditProfileForm.tsx
import React, { useEffect, useState } from 'react';
import FormBuilder, { AppFormData } from '../form/form_builder';
import { hoveredBgColor } from '../../configs/constants';
import { Stack, useTheme } from '@mui/material';
import { StyledFillButton, StyledTextButton } from '../../styled/filled_button';
import { inputStyles } from '../form/inout_sx_style';
import { useKeyListener } from '../../stores/providers/key_listener_provider';
import gameService from '../../services/game_service';
import { SwalAlert, SwalCompleted } from './swal_alert';
import { TimePicker } from 'rsuite';

interface GameCreateFormProps {
    closeDialog: (id?: number | null) => void
    fetchData?: ({ }) => void;
}

const formFields: AppFormData[] = [
    {
        name: 'endGameDatetime',
        inputType: 'dateTimePopup',
        value: '',
        required: true,
        placeholder: 'วันที่ปิดงวด',
        label: 'วันที่ปิดงวด'
    },
    {
        name: 'isAutoEndEnable',
        inputType: 'toggle',
        value: 'true',
        checked: true,
        required: false,
        placeholder: 'ปิดอัตโนมัติ',
        label: 'ปิดอัตโนมัติ',
        sx: {
            paddingRight: '18px',
            ":hover": { bgcolor: hoveredBgColor },
        }
    },
];

const GameCreateForm: React.FC<GameCreateFormProps> = ({ closeDialog, fetchData }) => {
    const [form, setForm] = useState<Record<string, string | number> | {}>({ endGameDatetime: '', isAutoEndEnable: 'true' })
    const [canSubmit, setCanSubmit] = useState<boolean>(false);
    const theme = useTheme();

    const handleFormSubmit = async () => {
        const tempForm = JSON.parse(JSON.stringify(form));
        const reqBody = { endGameDatetime: tempForm.endGameDatetime, isAutoEndEnable: JSON.parse(tempForm.isAutoEndEnable) };
        closeDialog();
        gameService.createGame(reqBody).then((res) => {
            const responseData = (res as any);
            if (responseData.status !== "error") {
                SwalCompleted();
                fetchData?.({ page: 1, withSwalComp: true });
            }
            else {
                SwalAlert({ title: 'ทำรายการไม่สำเร็จ', description: responseData.message });
            }
        })
    };

    const handleOnChanged = (data: Record<string, string | number>) => {
        setForm(data);
    };

    const isSomeEmptyRequiredField = (data: Record<string, string | number>) => {
        const hasEmptyOrNull = !!!Object.values(data)[0]
        return hasEmptyOrNull;
    }

    useEffect(() => {
        const findEmptyOrNull = isSomeEmptyRequiredField(form);
        setCanSubmit(!findEmptyOrNull);
    }, [form])

    // useEffect(() => {
    //     const handleEnterKey = (_: KeyboardEvent) => {
    //         handleFormSubmit();
    //     };
    //     keyListenerCtx?.addKeyListener('Enter', () => handleFormSubmit());
    //     return () => keyListenerCtx?.removeKeyListener('Enter', handleEnterKey);
    // }, [keyListenerCtx?.addKeyListener, keyListenerCtx?.removeKeyListener]);

    return (
        <>
            <FormBuilder onChange={handleOnChanged} showSubmitButton={false} itemsPerRow={1} formTitle='เปิดงวดใหม่' fields={formFields} onSubmit={handleFormSubmit} />
            <Stack sx={{ padding: '0px 32px 32px 0px' }} direction={'row'} justifyContent={'space-between'}>
                <StyledFillButton
                    onClick={() => handleFormSubmit()}
                    disabled={!canSubmit}
                    sx={{ width: 200, bgcolor: theme.palette.secondary.main }}>
                    เปิดงวด</StyledFillButton>
                <StyledTextButton onClick={() => closeDialog()} sx={{ width: 200, color: theme.palette.info[400] }}>ยกเลิก</StyledTextButton>
            </Stack>
        </>
    );
};

export default GameCreateForm;
