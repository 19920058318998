import dayjs from "dayjs"
import { USER_ROLE } from "./constants"
import { AgentRateDetails } from "../services/rate_service"
import { TicketWithSequencialIndex } from "../services/game_service"


export const MOCK_DEFAULT_PERMISSIONS_SA = [
    "USER_CREATE_AGENT",
    "USER_CREATE_OWNER",
    "USER_CREATE_STAFF",
    "USER_DISABLE_AGENT",
    "USER_DISABLE_OWNER",
    "USER_DISABLE_STAFF",
    "USER_DISABLE_SUPER_ADMIN",
    "USER_EDIT_AGENT",
    "USER_EDIT_OWNER",
    "USER_EDIT_STAFF",
    "USER_LIST",
    "USER_LIST_AGENT",
    "USER_LIST_OWNER",
    "USER_LIST_STAFF",
    "USER_PERMISSION_AGENT",
    "USER_PERMISSION_OWNER",
    "USER_PERMISSION_STAFF",
    "USER_PROFILE_CHANGE_PASSWORD",
    "USER_PROFILE_EDIT",
    "USER_VIEW_AGENT",
    "USER_VIEW_OWNER",
    "USER_VIEW_STAFF",
    "USER_VIEW_SUPER_ADMIN",
    "CREDIT_LIST_OWNER_CREDIT",
    "CREDIT_ADJUST_OWNER_CREDIT",
    "CREDIT_REQUEST_LIST",
    "CREDIT_REQUEST_APPROVE_REJECT",
    "CREDIT_TXN_LIST",
]

export const MOCK_DEFAULT_PERMISSION_W = [
    "USER_CREATE_AGENT",
    "USER_DISABLE_AGENT",
    "USER_DISABLE_STAFF",
    "USER_EDIT_AGENT",
    "USER_EDIT_STAFF",
    "USER_LIST",
    "USER_LIST_AGENT",
    "USER_LIST_STAFF",
    "USER_PERMISSION_AGENT",
    "USER_PERMISSION_STAFF",
    "USER_PROFILE_CHANGE_PASSWORD",
    "USER_PROFILE_EDIT",
    "USER_VIEW_AGENT",
    "USER_VIEW_STAFF",
    "CREDIT_REQUEST_CREATE",
    "CREDIT_REQUEST_LIST",
    "CREDIT_TXN_LIST"
]

export const MOCK_DEFAULT_PERMISSION_S = [
    "USER_DISABLE_AGENT",
    "USER_DISABLE_STAFF",
    "USER_EDIT_AGENT",
    "USER_EDIT_STAFF",
    "USER_LIST",
    "USER_LIST_AGENT",
    "USER_LIST_STAFF",
    "USER_PERMISSION_AGENT",
    "USER_PERMISSION_STAFF",
    "USER_PROFILE_CHANGE_PASSWORD",
    "USER_PROFILE_EDIT"
]

export const MOCK_DEFAULT_PERMISSION_A = [
    "USER_LIST",
    "USER_PROFILE_CHANGE_PASSWORD",
    "USER_PROFILE_EDIT"
]

export const MOCK_CREATE_USER_STAFF = {
    roleCode: USER_ROLE.STAFF,
    firstName: 'Tine',
    lastName: USER_ROLE.STAFF,
    phone: '0123456789',
    email: ``,
    username: ``,
    password: '123456',
}

export const MOCK_AGENT_RATE_DATA: AgentRateDetails[] = [
    {
        agentCode: 'M0001',
        agentFirstname: 'พี่อ้อย',
        agentLastname: '(การเงิน)',
        threeUpperStraightPrice: 500,
        threeUpperStraightPercent: 90,
        threeShufflePrice: 100,
        threeShufflePercent: 25,
        twoUpperStraightPrice: 70,
        twoUpperStraightPercent: 25,
        twoShufflePrice: 0,
        twoShufflePercent: 0,
        upperRunningPrice: 3,
        upperRunningPercent: 10,
        threeLowerStraightPrice: 100,
        threeLowerStraightPercent: 25,
        twoLowerStraightPrice: 70,
        twoLowerStraightPercent: 25,
        lowerRunningPrice: 4,
        lowerRunningPercent: 10,
        fourFloatingPrice: 100,
        fourFloatingPercent: 25,
        fiveFloatingPrice: 70,
        fiveFloatingPercent: 50,
    },
    {
        agentCode: 'M0002',
        agentFirstname: 'test',
        agentLastname: '002',
        threeUpperStraightPrice: 500,
        threeUpperStraightPercent: 90,
        threeShufflePrice: 100,
        threeShufflePercent: 25,
        twoUpperStraightPrice: 70,
        twoUpperStraightPercent: 25,
        twoShufflePrice: 0,
        twoShufflePercent: 0,
        upperRunningPrice: 3,
        upperRunningPercent: 10,
        threeLowerStraightPrice: 100,
        threeLowerStraightPercent: 25,
        twoLowerStraightPrice: 70,
        twoLowerStraightPercent: 25,
        lowerRunningPrice: 4,
        lowerRunningPercent: 10,
        fourFloatingPrice: 100,
        fourFloatingPercent: 25,
        fiveFloatingPrice: 70,
        fiveFloatingPercent: 50,
    },
    // Add more rows as needed...
];

export const MOCK_AGENT_NAME_LIST = [
    { label: 'พี่อ้อย(การเงิน)', value: 'พี่อ้อย(การเงิน)' },
    { label: 'note.ing', value: 'note.ing' },
    { label: 'test M012', value: 'test M012' },
    { label: 'ตลาดผลไม้', value: 'ตลาดผลไม้' },
    { label: 'Oh บ้านซ่าน', value: 'Oh บ้านซ่าน' },
    { label: 'ปิติชา วงละคร', value: 'ปิติชา วงละคร' },
    { label: 'Kamonrath Raiviboon', value: 'Kamonrath Raiviboon' },
    { label: 'Kamonrath Raiv', value: 'Kamonrath Raiv' },
    { label: 'ตา', value: 'ตา' },
    { label: 'น ตตต', value: 'น ตตต' },
    { label: 'รวย', value: 'รวย' },
    { label: 'เปรี้ยว', value: 'เปรี้ยว' },
    { label: 'พร', value: 'พร' },
    { label: 'jay', value: 'jay' },
    { label: 'jay2', value: 'jay2' },
    { label: 'เป้ย', value: 'เป้ย' },
    { label: 'aaom', value: 'aaom' },
    { label: 'dawthekop', value: 'dawthekop' },
    { label: 'aa', value: 'aa' },
    { label: 'test', value: 'test' },
    { label: 'ต้อ', value: 'ต้อ' },
    { label: 'K', value: 'K' },
    { label: 'winwin888', value: 'winwin888' },
    { label: 'เจ้ามือเฮียซัน', value: 'เจ้ามือเฮียซัน' },
    { label: 'นาย ธนาสัณจ์', value: 'นาย ธนาสัณจ์' },
    { label: 'man', value: 'man' },
    { label: 'Test', value: 'Test' },
    { label: 'NARONGRIT', value: 'NARONGRIT' },
    { label: 'อภิกขณา สุนทรานนท์', value: 'อภิกขณา สุนทรานนท์' },
    { label: 'ผกาแก้ว พุ่มมะเดื่อ', value: 'ผกาแก้ว พุ่มมะเดื่อ' },
    { label: 'Test - Art', value: 'Test - Art' },
    { label: 'Test - P\'Dang', value: 'Test - P\'Dang' },
    { label: 'ทดสอบ', value: 'ทดสอบ' },
    { label: 'ดลนภา เบญจรัตน์', value: 'ดลนภา เบญจรัตน์' },
    { label: 'manorom', value: 'manorom' },
    { label: 'แฟนเกด', value: 'แฟนเกด' },
    { label: 'แมว', value: 'แมว' },
    { label: 'อ้วน', value: 'อ้วน' },
    { label: 'ร้านพี่แดง', value: 'ร้านพี่แดง' },
    { label: 'Koy Ed', value: 'Koy Ed' },
    { label: 'Art ทัวร์', value: 'Art ทัวร์' },
    { label: 'Ann ทัวร์', value: 'Ann ทัวร์' },
    { label: 'แอน - น้อย', value: 'แอน - น้อย' }
];

export const MOCK_AGENT_TICKET: TicketWithSequencialIndex[] = Array.from({ length: 50 }, (_, index) => {
    const randomDate = new Date(Date.now() - Math.floor(Math.random() * 10000000000));
    const randomDateFormatted = dayjs(randomDate).format('DD-MM-YYYY')
    return {
        sequence: index + 1,
        id: `SEQ-${Math.floor(Math.random() * 100000)}-(${index + 1})`,
        betAmount: Math.floor(Math.random() * 1000) + 1,
        receiveAmount: Math.floor(Math.random() * 1000) + 1,
        deductAmount: Math.floor(Math.random() * 500),
        winAmount: Math.floor(Math.random() * 2000),
        ticketStatus: ['รับแล้ว', 'ยกเลิกแล้ว'][Math.floor(Math.random() * 2)],
        createdAt: randomDateFormatted,
        orderedBy: ['John', 'Jane', 'Alex', 'Chris', 'Taylor'][Math.floor(Math.random() * 5)],
    }
});



export const generateMockOrderData = (count: number) => {
    let mockDataArray: any[] = []; // Fix: Use an array instead of an object
    for (let i = 0; i <= count; i++) {
        mockDataArray.push({
            id: i,
            type: "บ",
            number: "",
            amount: "",
            isValid: null,
        });
    }
    return mockDataArray;
};
