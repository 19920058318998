import { de } from "react-day-picker/locale";
import baseAxios from "./axios_instance";
import { removeEmptyParam } from "./helper";
import { ITicketData, PaginationRequestParams, ResponseMetaData } from "./type";
import { useAuth } from "../stores/providers/auth_provider";
import { gameStatusEnum, gameTransitionType, STORAGE_KEY } from "../configs/constants";
import { AgentRateDetails } from "./rate_service";

type ResponseWithMeta<T> = {
    data?: GameSession[] | T[] | null;
    meta?: ResponseMetaData;
    message?: string;
}
export interface GameUpdateRequestBody {
    gameId: string | number;
    action: string;
}

export interface GameSession {
    id: number;
    ownerId: number;
    startGameDate: string;
    endGameDatetime: string;
    endGameActualDatetime: string;
    balance: number;
    ticketCount: number;
    isAutoEndEnable: boolean;
    startGameUserFirstname: string;
    startGameUserLastname: string;
    endGameUserFirstname: string;
    endGameUserLastname: string;
    isEndByAutoEnd: boolean;
    state: gameStatusEnum;
    nextPossibleTransitions: gameTransitionType[] | [];
}

export interface GameCreationRequestBody {
    endGameDatetime: string | Date;
    isAutoEndEnable: boolean;
}

export interface TicketOrderNumber {
    rowIndex: number;
    positionRaw: string;
    numberRaw: string;
    valueRaw: string;
    positionEditedRaw?: string;
    numberEditedRaw?: string;
    isValid?: boolean;
    priceRate?: AgentRateDetails
}

export interface TicketOrderRequestBody {
    sequence: string;
    numbers: TicketOrderNumber[];
}
export interface TicketOrderRequestParams {
    gameId: string;
    agentId: string;
    ticketId?: string;
}

export interface GameListRequestParams {
    startGameDate?: string;
}
export interface AgentListByGameRequestParams {
    gameId?: string;
    agentId?: string;
}

export interface OrderTicketRequestParams {
    gameId?: string;
    agentId?: string;
    ticketId?: string | null | undefined;
}


export interface AgentByGameSession {
    agentId: number;
    agentCode: string;
    agentFirstname: string;
    agentLastname: string;
    totalAmount: number;
    deductAmount: number;
    winAmount: number;
    totalBetAmount: number;
    totalDeductAmount: number;
    totalWinAmount: number;
    ticketCount: number;
    totalReceiveAmount: number;
    actions?: any
}
export interface TicketWithSequencialIndex {
    id?: number | string;
    sequence?: number | string;
    betAmount: number;
    receiveAmount: number;
    deductAmount: number;
    winAmount: number;
    ticketStatus: string;
    createdAt: string | Date;
    orderedBy: string;
}

export interface NextTicketSequencial {
    id: string | number;
    sequence: number | string;
}

export interface AgentTicketUpdateRequestBody {
    gameId: string | number;
    agentId: string | number;
    sequence: NextTicketSequencial[]
    ticket?: []
}


class GameService {
    async getUserToken() {
        return localStorage.getItem(STORAGE_KEY.ACCESS_TOKEN);
    }
    async getHeaders() {
        return { Authorization: `Bearer ${await this.getUserToken()}` }
    }

    async listGameSession(params: PaginationRequestParams<GameListRequestParams>) {
        const paramsWithoutEmpty = removeEmptyParam<PaginationRequestParams<GameListRequestParams>>
            ({ ...params });
        try {
            const response = await baseAxios.get<ResponseWithMeta<GameSession>>
                (`/games`,
                    {
                        params: paramsWithoutEmpty,
                        headers: await this.getHeaders()
                    });

            return response.data;
        } catch (error) {
            console.log('Error fetching owners:', error);
            return { data: [] };
        }
    };

    async getGameSessionById(id: string | number): Promise<GameSession | null> {
        try {
            const response = await baseAxios.get<GameSession>
                (`/games/${id}`,
                    {
                        params: { gameId: id },
                        headers: await this.getHeaders()
                    });

            return response.data;
        } catch (error) {
            console.log('Error fetching owners:', error);
            return null
        }
    };

    async createGame(req: GameCreationRequestBody): Promise<GameSession | null> {
        try {
            const response = await baseAxios.post<GameSession>("/games", req, { headers: await this.getHeaders() });
            return response.data;
        } catch (error) {
            const errorData = (error as any)?.response?.data;
            return errorData;
        }
    }
    async updateGame(req: GameUpdateRequestBody): Promise<GameSession | null> {
        try {
            const response = await baseAxios.put<GameSession>(`/games/${req.gameId}/actions/${req.action}`, req, { headers: await this.getHeaders() });
            return response.data;
        } catch (error) {
            const errorData = (error as any)?.response?.data;
            return errorData;
        }
    }

    async getActiveGame(ownerId: string | number): Promise<GameSession | null> {
        try {
            const response = await baseAxios.get<GameSession>
                (`/games/active`,
                    {
                        params: { ownerId },
                        headers: await this.getHeaders()
                    });

            return response.data;
        } catch (error) {
            console.log('Error fetching owners:', error);
            return null
        }
    };


    async listAgentByGameId(params: PaginationRequestParams<AgentListByGameRequestParams>) {
        const paramsWithoutEmpty = removeEmptyParam<PaginationRequestParams<AgentListByGameRequestParams>>
            ({ ...params });
        const { gameId } = params;

        try {
            const response = await baseAxios.get<ResponseWithMeta<AgentByGameSession[]>>
                (`games/${gameId}/agents`,
                    {
                        params: paramsWithoutEmpty,
                        headers: await this.getHeaders()
                    });

            return { data: response.data ?? [] };
        } catch (error) {
            console.log('Error fetching owners:', error);
            return { data: [] }
        }
    };
    async listTicketByAgentId(params: PaginationRequestParams<AgentListByGameRequestParams>) {
        const paramsWithoutEmpty = removeEmptyParam<PaginationRequestParams<AgentListByGameRequestParams>>({
            ...params
        });
        const { gameId, agentId } = params;

        try {
            const response = await baseAxios.get<{ data: TicketWithSequencialIndex[] }>(
                `games/${gameId}/agents/${agentId}/tickets`,
                {
                    params: paramsWithoutEmpty,
                    headers: await this.getHeaders()
                }
            );

            return response.data;
        } catch (error) {
            console.log('Error fetching tickets:', error);
            return { data: [] };
        }
    }

    async updateAgentTicketSequence(req: AgentTicketUpdateRequestBody) {
        const { agentId, gameId } = req;
        const apiPath = `/games/${gameId}/agents/${agentId}/tickets/sequence`;

        try {
            const headers = { headers: await this.getHeaders() };
            const { data } = await baseAxios.put<{ data: TicketWithSequencialIndex[] }>(apiPath, req.ticket, headers);
            return data;
        } catch (error: any) {
            return { data: [], errorData: error?.response?.data };
        }
    }

    async createTicketOrder(req: TicketOrderRequestBody, params: TicketOrderRequestParams): Promise<any | null> {
        const { gameId, agentId } = params;
        try {
            const response = await baseAxios.post<any>(
                `/games/${gameId}/agents/${agentId}/tickets`,
                req,
                { headers: await this.getHeaders() });
            return response.data;
        } catch (error) {
            const errorData = (error as any)?.response?.data;
            return errorData;
        }
    }

    async updateTicketOrder(req: TicketOrderRequestBody, params: TicketOrderRequestParams): Promise<any | null> {
        const { gameId, agentId, ticketId } = params;
        // /games/{ gameId } /agents/{ agentId } /tickets/{ ticketId }
        try {
            const response = await baseAxios.put<any>(
                `/games/${gameId}/agents/${agentId}/tickets/${ticketId}`,
                req,
                { headers: await this.getHeaders() });
            return response.data;
        } catch (error) {
            const errorData = (error as any)?.response?.data;
            return errorData;
        }
    }


    async getTicketOrderByNewTicket(params: OrderTicketRequestParams) {
        const paramsWithoutEmpty = removeEmptyParam<OrderTicketRequestParams>
            ({ ...params });
        const { gameId, agentId } = params;

        try {
            const response = await baseAxios.get<ITicketData>
                (`/games/${gameId}/agents/${agentId}/tickets/new`,
                    {
                        params: paramsWithoutEmpty,
                        headers: await this.getHeaders()
                    });
            return { data: response.data ?? null };
        } catch (error) {
            console.log('Error fetching owners:', error);
            return null;
        }
    };

    async getTicketOrderByTicketId(params: OrderTicketRequestParams) {
        const paramsWithoutEmpty = removeEmptyParam<OrderTicketRequestParams>
            ({ ...params });
        const { gameId, agentId, ticketId } = params;

        try {
            const response = await baseAxios.get<ITicketData>
                (`/games/${gameId}/agents/${agentId}/tickets/${ticketId}`,
                    {
                        params: paramsWithoutEmpty,
                        headers: await this.getHeaders()
                    });

            return { data: response.data ?? null };
        } catch (error) {
            console.log('Error fetching owners:', error);
            return null;
        }
    };


    // /games/{gameId}/agents/{agentId}/tickets/{ticketId}
}

const gameService = new GameService();
export default gameService;

