import { Dialog, Breakpoint } from "@mui/material";
import React, { createContext, useContext, useState, ReactNode } from "react";
import { SwalLeaveConfirm } from "../../components/dialog/swal_alert";

interface LeaveActionCallback {
    (): void;
}
interface DialogContextType {
    openDialog: (content: ReactNode, size?: Breakpoint, leaveCf?: boolean) => number;
    closeDialog: (id?: number | null) => void;
}

const DialogContext = createContext<DialogContextType | undefined>(undefined);

export function useDialog() {
    const context = useContext(DialogContext);
    if (!context) {
        throw new Error("useDialog must be used within a DialogProvider");
    }
    return context;
}

export default function DialogProvider({ children }: { children: ReactNode }) {
    const [dialogs, setDialogs] = useState<
        { id: number; content: ReactNode; open: boolean; size?: Breakpoint, leaveCf?: boolean }[]
    >([]);

    const createDialog = (content: ReactNode, size: Breakpoint = "lg", leaveCf = false) => {
        const id = Date.now(); // Unique id for each dialog
        setDialogs((prevDialogs) => [
            ...prevDialogs,
            { id, content, open: true, size, leaveCf },
        ]);
        return id;
    };

    const closeDialog = (id?: number | null) => {
        if (id !== undefined && id !== null) {
            setDialogs((prevDialogs) =>
                prevDialogs.map((dialog) =>
                    dialog.id === id ? { ...dialog, open: false } : dialog
                )
            );
        } else {
            setDialogs([]);
        }
    };

    const contextValue = {
        openDialog: (content: ReactNode, size?: Breakpoint, leaveCf?: boolean) => createDialog(content, size, leaveCf),
        closeDialog,
    };

    const swalLeaveConfig = (id: number) => SwalLeaveConfirm(
        () => () => {
            return closeDialog(id);
        },
        () => closeDialog(id),
        () => { }
    )

    return (
        <DialogContext.Provider value={contextValue}>
            {children}
            {dialogs.map((dialog) => (
                <DialogContainer
                    key={dialog.id}
                    open={dialog.open}
                    onClose={() => {
                        dialog.leaveCf ? swalLeaveConfig(dialog.id) : closeDialog(dialog.id)
                    }}
                    size={dialog.size}
                    leaveCf={dialog.leaveCf ?? false}
                >
                    {dialog.content}
                </DialogContainer>
            ))}
        </DialogContext.Provider>
    );
}

function DialogContainer({
    children,
    open,
    onClose,
    size = "lg",
    leaveCf = false,
}: {
    children: ReactNode;
    open: boolean;
    onClose: () => void;
    size?: Breakpoint;
    leaveCf?: boolean;
}) {
    return (
        <Dialog
            sx={{
                zIndex: 2000,
                width: { sm: '100vw', md: size }
            }}
            open={open}
            onClose={() => onClose()}
            maxWidth={size}
            fullWidth={true}>
            {children}
        </Dialog>
    );
}
