import { BetType } from "./constants";
import { LotteryTicketAtomNumberDto } from "./dto/LotteryTicketAtomNumberDto";
import { LotteryTicketNumberDto } from "./dto/LotteryTicketNumberDto";


export function toLotteryTicketNumberDto(lotteryTicketNumber: LotteryTicketNumberDto): LotteryTicketNumberDto {
    let lotteryTicketNumberDto: LotteryTicketNumberDto = {
        rowIndex: lotteryTicketNumber.rowIndex,
        betTypeRaw: null,
        positionRaw: lotteryTicketNumber.positionRaw,
        numberRaw: lotteryTicketNumber.numberRaw,
        valueRaw: lotteryTicketNumber.valueRaw,
        betTypeEditedRaw: null,
        positionEditedRaw: lotteryTicketNumber.positionEditedRaw,
        numberEditedRaw: lotteryTicketNumber.numberEditedRaw,
        valueEditedRaw: lotteryTicketNumber.valueEditedRaw,
        _isEdited: lotteryTicketNumber._isEdited,
        _isValid: lotteryTicketNumber._isValid,
        _betTypeRaw: lotteryTicketNumber._betTypeRaw,
        _betTypeEditedRaw: lotteryTicketNumber._betTypeEditedRaw,
        _appliedNumbers: lotteryTicketNumber._appliedNumbers,
        _totalAmount: lotteryTicketNumber._totalAmount,
        _messages: lotteryTicketNumber._messages
    }
    return lotteryTicketNumberDto;
}

export function toLotteryTicketAtomNumberDto(betType: BetType | null, number: string, position: string, value: string | null): LotteryTicketAtomNumberDto {
    let lotteryNumber: LotteryTicketAtomNumberDto = {
        betType,
        number,
        position,
        value: value || "-1",
        isWinPrize: false, // Default value for isWinPrize
        winPrize: 0 // Default value for winPrize
    };

    return lotteryNumber;
}