import { DndContext, PointerSensor, closestCenter, useSensor, useSensors } from "@dnd-kit/core";
import { restrictToParentElement, restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { SortableContext, useSortable, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import {
    Fab,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    debounce,
    styled,
    useTheme
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Stack } from "rsuite";
import Spacer from "../spacing/spacer";
import { Column } from "../table/hoverable_table_row";
import PromptTypo from "../typography/typography_prompt";
import { Edit } from "@mui/icons-material";

export type TableCellAlignment = 'inherit' | 'left' | 'center' | 'right' | 'justify';

interface SortableTableProps {
    columns: Column[];
    data: any[];
    onDragEnd: (event: any) => void;
    onDragOver?: (event: any) => void;
    onRowClick?: (id: any) => void;
    disabled?: boolean;
}

const StyledTableCell = styled(TableCell)({
    fontWeight: "bold",
});

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    cursor: "grab",
    "&:hover": {
        backgroundColor: theme.palette.action.hover,
    },
}));

const SortableRow: React.FC<{
    id: string;
    row: any;
    columns: Column[];
    alignment?: TableCellAlignment; disabled: boolean
    onRowClick?: (id: any) => void;
}> = (
    { id,
        row,
        alignment,
        columns,
        onRowClick,
        disabled = false,
    }) => {
        const { attributes, listeners, setNodeRef, transform, transition, isDragging, isOver } = useSortable({ id });
        const muiTheme = useTheme();
        const style = {
            transform: transform
                ? `${CSS.Transform.toString(transform)} ${isDragging ? 'scale(1.05)' : ''}`
                : undefined,
            transition: transition,
            zIndex: isDragging ? 1000 : 'auto',
            boxShadow: isDragging ? '0px 4px 12px rgba(10, 2, 43,0.25)' : 'none',
            willChange: 'transform, box-shadow, background-color',
            transformOrigin: 'center',
        };


        const handleRowClick = (id: any) => {
            onRowClick?.(id);
        }

        const [isHovered, setIsHovered] = useState(false);

        return (
            <StyledTableRow
                hover
                ref={setNodeRef}
                style={{ ...style }}
                sx={{ textAlign: alignment }}
                {...(disabled ? {} : { ...attributes, ...listeners })}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                {
                    Object.keys(row).map((columnKey, colIndex) => {
                        const colRef = columns[colIndex] as Column;
                        if (!colRef) return;
                        const value = row[columnKey];
                        const renderField = colRef.render ? colRef.render(value) : value ?? '-';
                        return <StyledTableCell
                            onClick={() => handleRowClick(row.id)}
                            sx={{
                                padding: '24px 18px',
                                userSelect: 'none',
                                fontWeight: colRef.highlighten ? 'bold' : 'regular',
                                color: colRef.highlighten ? '' : 'white',
                            }}
                            key={columnKey}
                            align={colRef.align ? colRef.align : (isNaN(+row[columnKey]) ? (colIndex === 0 ? 'left' : colRef.align) : 'right')}
                        >
                            {renderField}
                        </StyledTableCell>
                    })
                }
            </StyledTableRow >
        );
    };

const SortableTable: React.FC<SortableTableProps> = (
    {
        columns,
        data,
        onDragEnd,
        onDragOver,
        onRowClick,
        disabled = false,
    }) => {
    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                delay: 150, // กำหนดให้ต้องกดค้าง 150ms ก่อนลาก
                tolerance: 5, // ต้องขยับเมาส์อย่างน้อย 5px ก่อนลาก
            },
        })
    );
    const [disable, setDisable] = useState<boolean>(disabled);
    useEffect(() => {
        setDisable(disabled)
    }, [disabled]);


    return (
        <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={onDragEnd}
            onDragOver={onDragOver}
            onDragStart={() => { console.log('DRAG_START') }}
            modifiers={[restrictToVerticalAxis, restrictToParentElement]}
        >
            <Stack >
                {/* <PromptTypo variant="h5">  <FormControlLabel
                    control={
                        <Switch
                            checked={!disable}
                            onChange={(_: any, checked: boolean) => setDisable(!checked)}
                            color="info"
                        />
                    }
                    label={`Draggable :${!disable}`}
                    labelPlacement="end"
                /></PromptTypo> */}
            </Stack>
            <TableContainer component={Paper} sx={{ height: 'auto' }}>
                <Table stickyHeader sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                        <TableRow
                            className="row_head"
                            sx={{
                                height: '48px',
                                paddingTop: '24px',
                            }}>
                            {columns.map((column, index) => (
                                <TableCell
                                    style={{ minWidth: column.minWidth }}
                                    key={column.field}
                                    align={column.align ? column.align : 'left'}
                                // align={column.align ? 'left' : `${index == 0 ? 'left' : 'right'}`} 

                                >
                                    <PromptTypo variant="body2" fontWeight="bold">
                                        {column.label}
                                    </PromptTypo>
                                    <Spacer size="md" />
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <SortableContext
                        items={data.map((row) => row.id.toString())} // Use row.id as the unique identifier
                        strategy={verticalListSortingStrategy}
                    >
                        <TableBody>
                            {data.map((row) => (
                                <SortableRow
                                    key={row.id}
                                    id={row.id.toString()} // Make sure the ID is a string for SortableContext
                                    row={row}
                                    columns={columns}
                                    alignment="left"
                                    disabled={disable}
                                    onRowClick={onRowClick}
                                />
                            ))}
                        </TableBody>
                    </SortableContext>
                </Table>
            </TableContainer>
        </DndContext>
    );
};

export default SortableTable;
