import { arrayMove } from "@dnd-kit/sortable"
import { Box, useTheme } from '@mui/material'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Alignment from '../components/alignment'
import Spacer from '../components/spacing/spacer'
import { StyledContainer } from '../components/styled/styled_flex_container'
import { Column } from '../components/table/hoverable_table_row'
import SortableTable from '../components/table_sortable/mui_sortable_table'
import PromptTypo from '../components/typography/typography_prompt'
import { fullThaiDateFormat, generalDateFormat, generalDateTimeFormat, tableContentConfig } from '../configs/constants'
import { MOCK_AGENT_TICKET } from '../configs/mock'
import gameService, { AgentByGameSession, GameSession, TicketWithSequencialIndex } from '../services/game_service'
import { useAuth } from '../stores/providers/auth_provider'
import { useDialog } from '../stores/providers/dialog_provider'
import { StyledFillButton, StyledOutlineButton, StyledTextButton } from '../styled/filled_button'
import AgentOrderTableComponent from "../components/order/order_table_component"
import { SwalCompleted, SwalLoading } from "../components/dialog/swal_alert"
import { formatToCurrencyString } from "../helpers/number_formatter_helper"

interface AgentOrderingTicketPageProps { }

const AgentOrderingTicketPageComponent: React.FC<AgentOrderingTicketPageProps> = () => {
    const [activeGameSession, setActiveGameSession] = useState<GameSession | null | undefined>(undefined);
    const [isPristine, setIsPristine] = useState<boolean>(true);
    const [draggable, setDraggable] = useState<boolean>(true);
    const { openDialog, closeDialog } = useDialog();
    const [data, setData] = useState<TicketWithSequencialIndex[] | []>([]);
    const [agent, setAgent] = useState<AgentByGameSession | undefined>(undefined);
    const [tableDataFiltered, setTableDataFiltered] = useState<TicketWithSequencialIndex[]>([]);
    const { user } = useAuth();
    const { id } = useParams();
    const { gameId } = useParams();
    const muiTheme = useTheme();

    const columns: Column[] = [
        {
            field: 'sequence',
            label: 'Id',
            ...tableContentConfig.common,
            align: 'left',
            minWidth: 75,
        },
        {
            field: 'id',
            label: 'โพยใบที่',
            ...tableContentConfig.common,
            minWidth: 20,
        },
        {
            field: 'betAmount',
            label: 'ยอดแทง',
            ...tableContentConfig.numberTrailingZero,
            minWidth: 100,
            render: (value: string) => `${formatToCurrencyString(value)}`
        },
        {
            field: 'receiveAmount',
            label: 'ยอดรับ',
            ...tableContentConfig.numberTrailingZero,
            minWidth: 100,
            render: (value: string) => `${formatToCurrencyString(value)}`
        },
        {
            field: 'deductAmount',
            label: 'ยอดหัก',
            ...tableContentConfig.numberTrailingZero,
            minWidth: 100,
            render: (value: string) => `${formatToCurrencyString(value)}`
        },
        {
            field: 'winAmount',
            label: 'ยอดถูก',
            ...tableContentConfig.numberTrailingZero,
            minWidth: 100,
            render: (value: string) => `${formatToCurrencyString(value)}`
        },
        {
            field: 'ticketStatus',
            label: 'สถานะโพย',
            ...tableContentConfig.common,
            minWidth: 100,
            render: (value: string) => `${value === 'receive' ? 'รับแล้ว' : value}`
        },
        {
            field: 'betDate',
            label: 'วันที่แทง',
            ...tableContentConfig.dateTime,
            minWidth: 120,
            render: (value: string) => `${dayjs(value).format(generalDateTimeFormat)}`
        },
        {
            field: 'betBy',
            label: 'แทงโดย',
            ...tableContentConfig.common,
            minWidth: 100,
        },
        {
            field: 'sequence',
            label: '',
            minWidth: 100,
            align: 'center',
            render: (id) => <StyledTextButton sx={{
                bgcolor: muiTheme.palette.secondary.main,
                color: muiTheme.palette.common.white
            }}>แก้ไข</StyledTextButton>
        },
        // {
        //     field: 'sequence', label: '', align: 'left', render: (id) => <StyledFillButton sx={{
        //         color: muiTheme.palette.common.white,
        //         bgcolor: muiTheme.palette.secondary.main,
        //         ":hover": { color: muiTheme.palette.secondary.light }
        //     }}
        //         onClick={(e) => {
        //             e.stopPropagation();
        //             e.preventDefault();
        //             const target = e.target as Element;
        //             const noDrag = target.closest('[data-no-dnd]');
        //             !!noDrag && e.stopPropagation();
        //             console.log("valuevaluevaluevaluevalue", id);
        //             handleOpenDialog(id);
        //         }}
        //     >
        //         แก้ไขโพย
        //     </StyledFillButton>
        // },
    ];

    const fetchAgent = async () => {
        const response = await gameService.listAgentByGameId({ gameId, agentId: id });
        const listAgentInGame = response.data as AgentByGameSession[];
        const findNameOfMatchedAgentId = listAgentInGame.find(({ agentId }) => {
            return String(agentId) === String(id);
        });

        setAgent(findNameOfMatchedAgentId ?? undefined)

    }

    const fetchActiveGameSession = async () => {
        const ownerId = user.ownerId;
        const response = ownerId ? await gameService.getActiveGame(ownerId) : undefined;

        if (!response) {
            setActiveGameSession(null);
            return null;
        };
        setActiveGameSession(response);
        return true;
    }

    const fetchData = async () => {
        const ownerId = user.ownerId;
        const response = ownerId ? await gameService.listTicketByAgentId({ gameId, agentId: id }) : undefined;
        if (!response) return;
        setIsPristine(true);
        const dataWithActions = response.data?.map((el) => ({
            ...el,
            actions: id
        }));
        setData(dataWithActions);
        setTableDataFiltered(dataWithActions);
    }

    const handleDragEnd = (event: any) => {
        const { active, over } = event;
        if (!!active && !!over && active?.id !== over?.id) {
            setTableDataFiltered((prevRows) => {
                const oldIndex = prevRows.findIndex((row) => row?.id?.toString() === active.id);
                const newIndex = prevRows.findIndex((row) => row?.id?.toString() === over.id);
                setIsPristine(false);
                return arrayMove(prevRows, oldIndex, newIndex);
            });
        }
    };

    const decorateTicketsSequence = (
        tickets: TicketWithSequencialIndex[]
    ) => {
        const sequencial = tickets.map(ticket => ticket.id);
        return sequencial ?? []
    }

    const handleUpdateTicketSequence = async () => {
        const reAlignedSequencialData = tableDataFiltered.map((data, index) => {
            return {
                ...data,
                sequence: (Number(index)) + 1
            }
        })
        const req = {
            gameId: Number(gameId),
            agentId: Number(id),
            ticket: decorateTicketsSequence(reAlignedSequencialData)
        }

        gameService.updateAgentTicketSequence(JSON.parse(JSON.stringify(req))).then(() => {
            fetchData();
        })

    }

    const handleOpenDialog = (id: any) => {
        openDialog(
            <AgentOrderTableComponent
                closeCallback={async () => {
                    closeDialog();
                    await SwalLoading(async () => await fetchData().then(() => SwalCompleted()));
                }}
                onLeave={closeDialog}
                agentId={agent?.agentId}
                gameSession={activeGameSession ?? undefined}
                ownerId={user.ownerId}
                ticketId={id} />,
            "xl", true
        );
    };


    useEffect(() => {
        fetchActiveGameSession().then(async (res) => {
            if (!!res) {
                await fetchData();
            }
        })
        fetchAgent();
    }, [])

    return (
        <StyledContainer>
            <Box sx={{ flexDirection: 'row' }}>
                <PromptTypo>
                    รัฐลาลไทย - งวดวัน{dayjs(activeGameSession?.endGameDatetime).format(fullThaiDateFormat)}
                </PromptTypo>
                <Spacer size='sm' />
                <PromptTypo variant="h5">
                    รายการแทงหวย : {agent?.agentFirstname} {agent?.agentLastname}
                </PromptTypo>
                <Alignment position='centerRight'>
                    <StyledFillButton
                        disabled={isPristine}
                        onClick={handleUpdateTicketSequence}>
                        <PromptTypo>บันทึกลำดับ</PromptTypo>
                    </StyledFillButton>
                </Alignment>
            </Box>
            <Spacer size='lg' />
            <SortableTable
                disabled={!draggable}
                columns={columns}
                data={tableDataFiltered as unknown as []}
                onDragEnd={handleDragEnd}
                onRowClick={handleOpenDialog}

            />
        </StyledContainer>
    )
}

export default AgentOrderingTicketPageComponent
