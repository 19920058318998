import {
    Box,
    CircularProgress,
    Paper,
    Skeleton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    useTheme
} from "@mui/material";
import React, { useState } from "react";
import { sxTypoHeader6 } from "../../configs/text_styles";
import gameService, { AgentByGameSession, GameSession } from "../../services/game_service";
import rateService, { AgentRateDetails } from "../../services/rate_service";
import { StyledTextButton } from "../../styled/filled_button";
import AlertGame from "../alerts/alert_game";
import Spacer from "../spacing/spacer";
import PromptTypo from "../typography/typography_prompt";
import { rateDisplayTransform } from "../../configs/constants";
import { formatNumberString } from "../spring/string_helper";

const MOCK_AGENT_OPTIONS = [
    { label: 'นายสมชาย ใจดี', value: 'somchai' },
    { label: 'นางสาวสมฤดี สุขสันต์', value: 'somrudee' },
    { label: 'นายก้องภพ สงวนศักดิ์', value: 'kongpop' },
    { label: 'นางสาวชลธิชา บัวงาม', value: 'chonthicha' },
    { label: 'นายอาทิตย์ ทองคำ', value: 'arthit' },
    { label: 'นางสาวพิมพ์ใจ บุญส่ง', value: 'pimjai' },
    { label: 'นายเอกชัย ทรัพย์สมบูรณ์', value: 'ekachai' },
    { label: 'นางสาววิไลพร จันทร์เพ็ญ', value: 'wilaiporn' },
    { label: 'นายธนพล กิจเจริญ', value: 'thanapol' },
    { label: 'นางสาวชุติมา พูนทรัพย์', value: 'chutima' },
];

interface OrderTableAgentRateComponentProps {
    gameSession?: GameSession;
    summaryPrice?: String;
    ownerId?: string | number;
    agentId?: string | number;
    priceRate?: AgentRateDetails | null
}const OrderTableAgentRateComponent: React.FC<OrderTableAgentRateComponentProps> = (
    { gameSession, ownerId, agentId, summaryPrice, priceRate }
) => {
    const [rateData, setRateData] = React.useState<any>(undefined);
    const [areLoading, setAreLoading] = useState({ rate: false, game: false, ticket: false });
    const [agentData, setAgentData] = React.useState<any>();
    const muiTheme = useTheme();

    const handleNumberInput = (input: string) => {
        // processLotteryTicketNumber();
    }

    const fetchAgent = async () => {
        console.log('gameSession', gameSession);
        if (!ownerId) return;
        try {
            setAreLoading({ ...areLoading, game: true });
            const response = await gameService.listAgentByGameId({ gameId: gameSession?.id, agentId });
            const responseData = response?.data ?? [];
            const responseArray = responseData as AgentByGameSession[];
            const agentData = responseArray.length > 0 ? responseArray[0] : null;
            setAgentData(agentData);
            setAreLoading({ ...areLoading, game: false });


        } catch (error) {
            setAreLoading({ ...areLoading, game: false });
        }
    }

    const fetchRates = async () => {
        if (!ownerId) return;
        try {
            setAreLoading({ ...areLoading, rate: true });
            const response = await rateService.listOwnerRates(+ownerId, { ownerId: ownerId, agentID: agentId });
            const responseData = response?.data ?? [];
            const agentRate = responseData.find((f) => f.agentId === agentId) ?? null;

            setAreLoading({ ...areLoading, rate: false });
        } catch (error) {
            // setAreLoading({ ...areLoading, rate: false });
        }
    }

    React.useEffect(() => {
        fetchAgent();
    }, []);
    React.useEffect(() => {
        if (priceRate) {
            const agentRateTransformed = rateDisplayTransform(JSON.parse(JSON.stringify(priceRate)));
            setRateData(agentRateTransformed);
        }
    }, [priceRate]);

    return (
        <TableContainer component={Paper} style={{ marginTop: "16px", overflowX: 'hidden', height: '100%' }}
            sx={{
                bgcolor: `${muiTheme.palette.background.default}`,
                borderRadius: "4px",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
                overflow: "auto",
                minWidth: '300px'
            }}>
            <AlertGame gameSession={gameSession} />
            <Stack direction={'row'}
                sx={{ padding: 2, marginTop: 1 }}>
                <PromptTypo variant="h6"
                    style={{ marginTop: '6px' }}
                    sx={sxTypoHeader6}>
                    สมาชิก
                </PromptTypo>
                <Spacer size="xl" />
                <Box sx={{ width: '100%' }}>
                    <Stack direction={'column'} sx={{ alignItems: 'flex-start' }}>
                        <StyledTextButton disabled value={'AGENT_DISPLAY_NAME'} >
                            <PromptTypo sx={{ fontWeight: 'bold' }} variant="subtitle1">
                                {`${agentData?.agentFirstname ?? ''}  ${agentData?.agentLastname ?? ''}`}
                            </PromptTypo>
                        </StyledTextButton>
                        <StyledTextButton disabled value={'AGENT_DISPLAY_NAME'} >
                            <PromptTypo variant="body2">
                                {`( ${agentData?.agentCode ?? ''} )`}
                            </PromptTypo>
                        </StyledTextButton>
                    </Stack>
                </Box>
            </Stack>
            <Box sx={{ padding: 2 }}>
                <Typography
                    variant="h6"
                    sx={sxTypoHeader6}
                >
                    ราคาจ่าย
                </Typography>
            </Box>
            <>
                {!areLoading.rate ? (
                    <TableContainer
                        sx={{
                            height: 'calc(85vh - 475px)',
                            overflowY: 'none',
                            overflowX: 'none',
                        }}
                    >
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ fontWeight: 'bold' }}>ชนิด</TableCell>
                                    <TableCell align="right" style={{ fontWeight: 'bold' }}>
                                        จ่าย
                                    </TableCell>
                                    <TableCell align="right" style={{ fontWeight: 'bold' }}>
                                        ลด (%)
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rateData?.map((row: any, index: number) => (
                                    <TableRow key={index}>
                                        <TableCell>{row.type}</TableCell>
                                        <TableCell align="right">{row.pay}</TableCell>
                                        <TableCell align="right">{row.discount}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                    <TableContainer
                        sx={{
                            height: 'calc(85vh - 475px)',
                            overflowY: 'none',
                            overflowX: 'none',
                        }}
                    >
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ fontWeight: 'bold' }}>
                                        <Skeleton variant="text" width="40%" />
                                    </TableCell>
                                    <TableCell align="right" style={{ fontWeight: 'bold' }}>
                                        <Skeleton variant="text" width="20%" />
                                    </TableCell>
                                    <TableCell align="right" style={{ fontWeight: 'bold' }}>
                                        <Skeleton variant="text" width="20%" />
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {/* Render Skeleton rows while loading */}
                                {Array.from(new Array(5)).map((_, index) => (
                                    <TableRow key={index}>
                                        <TableCell>
                                            <Skeleton variant="text" width="80%" />
                                        </TableCell>
                                        <TableCell align="right">
                                            <Skeleton variant="text" width="40%" />
                                        </TableCell>
                                        <TableCell align="right">
                                            <Skeleton variant="text" width="30%" />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </>
            <Spacer size="lg" />
            <Paper sx={{ padding: { lg: 5, md: 1, sm: 0 } }}>
                {/* <PromptTypo sx={{ width: '300px' }} variant="h6">
                    <span>
                        ยอดโพยใบนี้
                    </span>
                    <span> = </span>
                    <span style={{ color: muiTheme.palette.secondary.light }}>
                        {formatNumberString(`${summaryPrice ?? 0}`)} บาท
                    </span>
                </PromptTypo> */}
            </Paper>
        </TableContainer >
    );
};

export default OrderTableAgentRateComponent;
