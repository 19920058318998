import { Close, WarningAmberOutlined } from '@mui/icons-material'
import { Box, Stack, TextField, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import AlertGame from '../components/alerts/alert_game'
import { SwalCompleted, SwalLeaveConfirm, SwalLoading } from '../components/dialog/swal_alert'
import OdmntSelectSearch from '../components/odmnt_selector/odmnt_selector'
import AgentOrderTableComponent from '../components/order/order_table_component'
import Spacer from '../components/spacing/spacer'
import { StyledContainer } from '../components/styled/styled_flex_container'
import { Column, DataRow } from '../components/table/hoverable_table_row'
import PaginatedTable from '../components/template/pagination_table_page'
import PromptTypo from '../components/typography/typography_prompt'
import { tableContentConfig } from '../configs/constants'
import { formatToCurrencyString } from '../helpers/number_formatter_helper'
import gameService, { AgentByGameSession, GameSession } from '../services/game_service'
import { useAuth } from '../stores/providers/auth_provider'
import { useDialog } from '../stores/providers/dialog_provider'
import { StyledFillButton, StyledOutlineButton, StyledTextButton } from '../styled/filled_button'

const AgentOrderingPage: React.FC = () => {
    const muiTheme = useTheme();
    const navigate = useNavigate();
    const { user } = useAuth();
    const { openDialog, closeDialog } = useDialog();
    const [tableData, setTableData] = useState<AgentByGameSession[]>([]);
    const [tableDataFiltered, setTableDataFiltered] = useState<AgentByGameSession[]>([]);
    const [searchOptions, setSearchOptions] = useState<any>([]);
    const [activeGameSession, setActiveGameSession] = useState<GameSession | null | undefined>(undefined);
    const [filter, setFilter] = useState<{ searchText: '', searchCode: '', selectedAgentId: '' }>({ searchText: '', searchCode: '', selectedAgentId: '' });

    const handleInputChange = (searchValue: any) => {
        setFilter({
            ...filter,
            searchText: searchValue?.label ?? '',
            selectedAgentId: searchValue?.agentId ?? ''
        })
    };

    const handleCodeChange = (searchCode: any) => {
        setFilter({ ...filter, searchCode: searchCode ?? '' })
    };

    const handleSearch = () => {
        let newData = JSON.parse(JSON.stringify(tableData));
        if (!!filter.selectedAgentId) {
            newData = newData.filter((f: AgentByGameSession) =>
                (+f.agentId === +filter.selectedAgentId));
        }

        setTableDataFiltered(newData)
    };

    const handleClear = () => {
        setFilter({ searchText: '', searchCode: '', selectedAgentId: '' });
        handleInputChange('');
        setTableDataFiltered(tableData);
    }

    const handleOnEnter = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event?.key === 'Enter') {
            handleSearch();
            event.preventDefault();
        }
    }

    const fetchData = async (agentId?: number | string) => {
        if (!activeGameSession) return;
        try {
            const response = await gameService.listAgentByGameId({ gameId: activeGameSession?.id, agentId });
            if (!response || Array(response).length === 0) return;

            let dataWithOptionalFields = JSON.parse(JSON.stringify(response)).data;

            dataWithOptionalFields = dataWithOptionalFields.map((field: AgentByGameSession) => (
                { ...field, id: field.agentId, actions: { id: field.agentId }, agentName: `${field.agentFirstname} ${field.agentLastname}` }
            ));


            const optionData = JSON.parse(JSON.stringify(response)).data.map((option: AgentByGameSession) => {
                return { ...option, label: `${option.agentFirstname}  ${option.agentLastname}`, value: option.agentId }
            })

            setSearchOptions(optionData);

            setTableData(dataWithOptionalFields);
            setTableDataFiltered(dataWithOptionalFields);

        } catch (error) {
            // console.error("Error fetching rates:", error);
        }
    }

    const theme = useTheme();
    const { paper: bgPaper, default: bgDefault } = theme.palette.background;
    const textColor = theme.palette.text.secondary;
    const secondaryColor = theme.palette.secondary.main;

    const handleOpenDialog = ({ type = '', id = undefined, agentId = undefined }) => {
        if (type === 'create-order')
            openDialog(
                <AgentOrderTableComponent
                    closeCallback={async () => {
                        closeDialog();
                        await SwalLoading(async () => await fetchData().then(() => SwalCompleted()));
                    }}
                    onLeave={closeDialog}
                    gameSession={activeGameSession ?? undefined}
                    ownerId={user.ownerId}
                    agentId={agentId} />,
                "xl", true
            );
    };

    const handleCloseDialog = () => {
        SwalLeaveConfirm(
            () => () => console.log("บันทึกและออก"), // บันทึกและออก
            () => console.log("ออกโดยไม่บันทึก"), //ออกโดยไม่บันทึก
            () => console.log("ยกเลิก") //ยกเลิก
        )
    }

    const columns: Column[] = [
        {
            field: 'agentName', label: 'รายชื่อ Agent', ...tableContentConfig.common,
            minWidth: 150,
            render: (value: string) => `${value}`
        },

        {
            field: 'status', label: 'สถานะ', ...tableContentConfig.common,
            minWidth: 50,
            render: (value: string) => `รับแล้ว`

        },
        {
            field: 'totalBetAmount', label: 'ยอดรวม',
            ...tableContentConfig.number,
            minWidth: 100,
            render: (value: string) => `${formatToCurrencyString(value)}`
        },
        {
            field: 'totalDeductAmount', label: 'ยอดหัก %', ...tableContentConfig.number,
            minWidth: 100,
            render: (value: string) => `${formatToCurrencyString(value)}`
        },
        {
            field: 'totalWinAmount', label: 'ยอดถูก', ...tableContentConfig.number,
            minWidth: 100,
            render: (value: string) => `${formatToCurrencyString(value)}`
        },
        {
            field: 'ticketCount', label: 'จำนวนโพย', ...tableContentConfig.numberTrailingZero,
            minWidth: 50,
            render: (value: string) => `${value}`
        },
        {
            field: 'actions',
            label: 'ตัวเลือก',
            ...tableContentConfig.action,
            minWidth: 150,
            render: (value: any) =>
                <Stack direction={'row'} width={180} spacing={2}>
                    <StyledFillButton
                        sx={{
                            color: theme.palette.common.white,
                            bgcolor: secondaryColor,
                            ":hover": { color: theme.palette.secondary.light }
                        }}
                        onClick={() => {
                            handleOpenDialog({ type: 'create-order', id: value.id, agentId: value.id })
                        }}>
                        แทงโพย
                    </StyledFillButton>
                    <StyledFillButton sx={{ color: theme.palette.common.white, bgcolor: secondaryColor, ":hover": { color: theme.palette.secondary.light } }}
                        onClick={() => {
                            navigate(`game/${activeGameSession?.id}/${value.id}`)
                            // handleOpenDialog({ type: 'create-order' })
                        }}>
                        ดูโพย
                    </StyledFillButton>
                </Stack>
        },
    ];

    const fetchActiveGameSession = async () => {
        const ownerId = user.ownerId;
        const response = ownerId ? await gameService.getActiveGame(ownerId) : undefined;

        if (!response) {
            setActiveGameSession(null);
            return;
        };

        setActiveGameSession(response);
    }

    useEffect(() => {
        fetchActiveGameSession();
    }, []);


    useEffect(() => {
        fetchData();
    }, [activeGameSession]);

    return (
        <StyledContainer>
            <PromptTypo variant="h5">รายการแทงหวย</PromptTypo>
            <Spacer size='sm' />
            {(!!activeGameSession) && <AlertGame gameSession={activeGameSession} />}
            {(activeGameSession === null) && <Box
                sx={{ display: 'flex', alignItems: 'center', color: muiTheme.palette.secondary.light }}>
                <WarningAmberOutlined sx={{ margin: '5px' }} />
                <PromptTypo>
                    ไม่พบงวดที่เปิดในขณะนี้
                </PromptTypo>
            </Box>}
            <Spacer size='sm' />
            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                <Stack direction={'row'} alignItems={'center'}>
                    <PromptTypo sx={{ width: 'auto' }}>ค้นหา Agent</PromptTypo>
                    <Box sx={{ width: 280 }}>
                        <OdmntSelectSearch
                            onEnterCallBack={() => handleSearch()}
                            onChange={(value) => { handleInputChange(value) }}
                            options={searchOptions} title={"ค้นหา Agent"} /></Box>
                    <Spacer size='xl' />
                    <PromptTypo sx={{ width: 80 }}>รหัส Agent</PromptTypo>
                    <TextField
                        onKeyDown={handleOnEnter}
                        onChange={(value) => { handleCodeChange(value) }}
                        placeholder='กรอกรหัส Agent'
                        sx={{ padding: '12px 12px', width: 280 }}
                        variant="outlined"
                        size="small"
                    />
                    <Spacer size='md' />
                    <StyledOutlineButton
                        disabled={false}
                        onClick={handleSearch}>
                        <PromptTypo>ค้นหา</PromptTypo>
                    </StyledOutlineButton>
                    <StyledTextButton
                        sx={{
                            visibility: (filter.selectedAgentId || filter.searchCode) ? 'visible' : 'hidden',
                            width: '100px',
                            height: '30px'
                        }}
                        onClick={handleClear}
                        color='error'>
                        <Close color='error' />
                        <Spacer size="xs" />
                        <PromptTypo variant='body2'>
                            ล้างค่า
                        </PromptTypo>

                    </StyledTextButton>
                </Stack>
            </Stack>
            <Spacer size='md' />
            <PaginatedTable
                stickyActions={true}
                stickyHeader={false}
                showPagination={false}
                columns={columns}
                data={tableDataFiltered as unknown as DataRow[] || []} />
        </StyledContainer>
    );
};

export default AgentOrderingPage;
