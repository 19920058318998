import React, { createContext, useContext, useState } from "react";
import { Snackbar } from "@mui/material";

interface SnackbarContextType {
  show: (message: string, isError?: boolean) => void;
}

const SnackbarContext = createContext<SnackbarContextType | undefined>(
  undefined
);

export const SnackbarProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState<string>("");
  const [isError, setIsError] = useState<boolean>(false); // Add isError state

  const show = (message: string, isError: boolean = false) => {
    setMessage(message);
    setIsError(isError); // Update isError state
    setOpen(true);

    setTimeout(() => {
      setOpen(false);
    }, 2000);
  };

  return (
    <SnackbarContext.Provider value={{ show }}>
      {children}
      <Snackbar
        sx={{
          zIndex: 9999,
          "& .MuiSnackbarContent-root": {
            backgroundColor: isError ? "red" : "green", // Set color based on isError
            color: "white", // Ensure text is readable
            fontWeight: "bold", // Optional: Make text stand out
          },
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        autoHideDuration={2000}
        message={message}
      />
    </SnackbarContext.Provider>
  );
};

export const useSnackbar = (): SnackbarContextType => {
  const context = useContext(SnackbarContext);
  if (!context) {
    throw new Error("useSnackbar must be used within a SnackbarProvider");
  }
  return context;
};      
