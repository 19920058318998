export const formatToCurrencyString = (
    value: number | string,
    locale: string = 'en-US',
    options?: Intl.NumberFormatOptions
): string => {
    // Convert the input to a number if it's a string
    const numberValue = typeof value === 'string' ? parseFloat(value) : value;

    // Handle NaN cases
    if (isNaN(numberValue)) {
        throw new Error('Invalid number provided for formatting.');
    }

    // Default options to format as a decimal with two fraction digits
    const defaultOptions: Intl.NumberFormatOptions = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        useGrouping: true,
    };

    // Merge default options with any provided options
    const formatOptions = { ...defaultOptions, ...options };

    const formatter = new Intl.NumberFormat(locale, formatOptions);

    return formatter.format(numberValue);
};


export const isNumeric = (str: number) => {
    if (typeof str != "string") return false
    return !isNaN(str) &&
        !isNaN(parseFloat(str))
}