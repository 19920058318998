
import { TimerOutlined, TimerOffOutlined } from '@mui/icons-material';
import { Alert, AlertProps, AlertTitle, Stack } from '@mui/material';
import dayjs from 'dayjs';
import React, { useState, useEffect } from 'react';
import { gameStateDisplay, gameStatusEnum, gameStateBgColorMap, fullThaiDateFormat } from '../../configs/constants';
import PromptTypo from '../typography/typography_prompt';
import { GameSession } from '../../services/game_service';

interface AlertGameProps {
    gameSession?: GameSession;
}

const AlertGame: React.FC<AlertGameProps> = ({
    gameSession,
}): any => {
    const currentGameState = gameSession?.state;
    const currentGameStateDisplay = gameStateDisplay[currentGameState ?? ''];
    const isGameOpened = currentGameState === gameStatusEnum.opened;
    const bgAlert = gameStateBgColorMap[currentGameState ?? ''];

    const alertIcon = isGameOpened ? <TimerOutlined /> : <TimerOffOutlined />

    return (
        <Alert icon={alertIcon} style={{ display: 'flex', alignItems: 'center' }}
            variant={`filled`} severity={`${bgAlert}`} sx={{ color: 'white' }}>
            <AlertTitle> <PromptTypo variant='h5'>
                หวยรัฐบาลไทย - งวดวัน{dayjs(gameSession?.endGameDatetime).format(fullThaiDateFormat)}
            </PromptTypo></AlertTitle>
            <PromptTypo variant='body1'>
                สถานะ : {currentGameStateDisplay}
            </PromptTypo>
        </Alert>
    );
};

export default AlertGame;
