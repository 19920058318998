import React, { useEffect, useState } from "react";
import { TextField, IconButton, InputAdornment, Stack } from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { ChevronLeft, ChevronRight, ForkLeft, RampRight, SwipeLeft } from "@mui/icons-material";
import PromptTypo from "../typography/typography_prompt";

const NumberInput: React.FC<{
    value?: number,
    onNumberChanged?: (number: number) => void
    onNumberIncreased?: () => void
    onNumberDecreased?: () => void
}> = ({
    value = 1,
    onNumberChanged,
    onNumberIncreased,
    onNumberDecreased
}) => {
        const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            onNumberChanged?.(Number(event.target.value));
        };

        return (
            <TextField
                type="number"
                value={value}
                onChange={handleChange}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <IconButton size="large" color="secondary" onClick={onNumberDecreased} disabled={value <= 1}>
                                <ChevronLeft />
                            </IconButton>
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton size="large" color="secondary" onClick={onNumberIncreased}>
                                <ChevronRight />
                            </IconButton>
                        </InputAdornment>
                    ),
                    inputProps: {
                        min: 1, // Set min value
                        style: { MozAppearance: "textfield" },
                    },
                }}
                sx={{
                    "& input[type=number]": {
                        MozAppearance: "textfield", // Firefox fix
                        "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
                            WebkitAppearance: "none", // Hide number input spinner for Chrome/Safari
                            margin: 0,
                        },
                    },
                }}
            />
        );
    };

export default NumberInput;
