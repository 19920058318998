import { Autocomplete, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import React, { useState } from 'react';
import { Close } from '@mui/icons-material';
import '../../App.css'


export interface OdmntOption {
    label: string;
    value?: string | number | null | undefined;
}

interface SelectSearchProps {
    options: OdmntOption[];
    title: string;
    onChange?: (value: any) => void;
    onEnterCallBack?: () => void;
}

const OdmntSelectSearch: React.FC<SelectSearchProps> = ({ options, title, onChange, onEnterCallBack }) => {
    const [selectedValue, setSelectedValue] = useState<OdmntOption | null>(null);
    const [searchQuery, setSearchQuery] = useState<string>('');

    const filteredOptions = options.filter((option) =>
        option.label.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const handleOnChange = (val: any) => {
        setSelectedValue(val)
        onChange?.(val);
    }

    const handleOnEnter = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event?.key === 'Enter') {
            onEnterCallBack?.();
            event.preventDefault();
        }
    }

    return (
        <FormControl fullWidth>
            <Autocomplete
                options={filteredOptions}
                value={selectedValue}
                onChange={(_, newValue) => handleOnChange(newValue)}
                getOptionLabel={(option) => option.label}
                clearIcon={<Close color='error'></Close>}
                popupIcon
                renderInput={(params) =>
                    <TextField
                        onKeyDown={handleOnEnter}
                        {...params}
                        sx={{ padding: '12px 12px' }}
                        variant="outlined"
                        size="small"
                        placeholder={title}
                    />
                }
            />
        </FormControl>
    );
};


export default OdmntSelectSearch;
