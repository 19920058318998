export interface IErrorResponseDisplay {
    en: string;
    th?: string;
}

export const ERROR_RESPONSE = {
    CREDIT: {
        OWNER_NULL: {
            code: "C_001",
            message: "Owner ID cannot be null.",
            messageTh: "รหัสเจ้าของไม่สามารถเป็นค่าว่างได้"
        },
        NO_CREDIT_BY_OWNER: {
            code: "C_002",
            message: "Credit not found for specified owner.",
            messageTh: "ไม่พบเครดิตสำหรับเจ้าของที่ระบุ"
        },
    },
    CREDIT_REQUEST: {
        REQUEST_NOT_FOUND: {
            code: "CR_001",
            message: "Request not found.",
            messageTh: "ไม่พบคำขอ"
        },
        CANNOT_UPDATE_OWN_REQUEST: {
            code: "CR_002",
            message: "Request user can't update its own request.",
            messageTh: "ผู้ใช้งานไม่สามารถอัปเดตคำขอของตนเองได้"
        },
        REQUEST_ALREADY_DONE: {
            code: "CR_003",
            message: "This request is already approved or rejected. Can't update its status.",
            messageTh: "คำขอนี้ได้รับการอนุมัติหรือปฏิเสธแล้ว ไม่สามารถอัปเดตสถานะได้"
        },
        USER_NOT_FOUND: {
            code: "CR_004",
            message: "User not found.",
            messageTh: "ไม่พบผู้ใช้งาน"
        },
        SUPER_ADMIN_ONLY: {
            code: "CR_005",
            message: "Super Admin can only request credit adjustment.",
            messageTh: "เฉพาะ Super Admin เท่านั้นที่สามารถขอปรับเครดิตได้"
        },
        NO_OWNER_ID: {
            code: "CR_006",
            message: "Please specify the owner of the credit request.",
            messageTh: "กรุณาระบุเจ้าของคำขอเครดิต"
        },
        NOT_SUPPORTED_TYPE: {
            code: "CR_007",
            message: "Please specify the supported type of credit request.",
            messageTh: "กรุณาระบุประเภทของคำขอเครดิตที่รองรับ"
        },
        NO_BANK_ID: {
            code: "CR_008",
            message: "Please specify bank account ID for credit topup request.",
            messageTh: "กรุณาระบุรหัสบัญชีธนาคารสำหรับการเติมเครดิต"
        },
        INVALID_DATE_PARAMS: {
            code: "CR_009",
            message: "dateRequest should not coexist with dateRequestFrom or dateRequestTo.",
            messageTh: "dateRequest ไม่ควรมีพร้อมกับ dateRequestFrom หรือ dateRequestTo"
        },
        MISMATCH_ID: {
            code: "CR_010",
            message: "The request ID in the URL does not match the ID in payload.",
            messageTh: "รหัสคำขอใน URL ไม่ตรงกับรหัสในข้อมูลที่ส่ง"
        },
        INVALID_STATUS: {
            code: "CR_011",
            message: "Invalid status provided.",
            messageTh: "สถานะที่ระบุไม่ถูกต้อง"
        },
    },
    GAME: {
        GAME_NOT_FOUND: {
            code: "G_001",
            message: "Game not found.",
            messageTh: "ไม่พบงวด"
        },
        RESERVED_STATE: {
            code: "G_002",
            message: "Cannot transition to 'goto' state.",
            messageTh: "ไม่สามารถเปลี่ยนไปยังสถานะ 'goto' ได้"
        },
        INVALID_TRANSITION: {
            code: "G_003",
            message: "Invalid transition.",
            messageTh: "การเปลี่ยนสถานะไม่ถูกต้อง"
        },
        NO_INITIAL_STATE: {
            code: "G_004",
            message: "FSM cannot be created without an initial state.",
            messageTh: "ไม่สามารถสร้าง FSM ได้หากไม่มีสถานะแรกเริ่ม"
        },
        HAS_ACTIVE_GAME: {
            code: "G_005",
            message: "There are active game. Cannot create a new game until active game is cleared or finished.",
            messageTh: "มีงวดที่กำลังดำเนินการอยู่ ไม่สามารถสร้างงวดใหม่ได้จนกว่างวดที่กำลังดำเนินการจะเสร็จสิ้น"
        },
        NO_OWNER_ID: {
            code: "G_006",
            message: "No owner specified.",
            messageTh: "ไม่ได้ระบุ Owner"
        },
        NO_START_USER_ID: {
            code: "G_007",
            message: "No start game user specified.",
            messageTh: "ไม่ได้ระบุผู้ใช้งานในการเปิดงวด"
        },
        NO_USER: {
            code: "G_008",
            message: "No user specified.",
            messageTh: "ไม่ได้ระบุผู้ใช้งาน"
        },
    },
    OWNER_PRICE_RATE: {
        NO_USER_ID: {
            code: "PR_001",
            message: "No user ID specified.",
            messageTh: "ไม่ได้ระบุรหัสผู้ใช้งาน"
        },
        UNAUTHORIZED_VIEW_RATE: {
            code: "PR_002",
            message: "Unauthorized to view rates for this owner.",
            messageTh: "ไม่ได้รับอนุญาตให้ดูอัตราสำหรับ Owner รายนี้"
        },
    },
    USER: {
        ROLE_NOT_FOUND: {
            code: "U_001",
            message: "Role not found.",
            messageTh: "ไม่พบ Role"
        },
        CANNOT_CREATE_STAFF_AGENT: {
            code: "U_002",
            message: "Super Admin cannot create staff/agent.",
            messageTh: "Super Admin ไม่สามารถสร้างพนักงาน/ตัวแทนได้"
        },
        USER_NOT_FOUND: {
            code: "U_003",
            message: "User not found.",
            messageTh: "ไม่พบผู้ใช้งาน"
        },
        ROLE_CHANGE_NOT_ALLOWED: {
            code: "U_004",
            message: "Cannot change user role.",
            messageTh: "ไม่สามารถเปลี่ยน Role ผู้ใช้งานได้"
        },
        NO_ROLE_ID: {
            code: "U_005",
            message: "No user role specified.",
            messageTh: "ไม่ได้ระบุ Role ผู้ใช้งาน"
        },
        NO_PERMISSION: {
            code: "U_006",
            message: "No permission.",
            messageTh: "คุณไม่มีสิทธิ์ในการทำรายการนี้"
        },
        INVALID_ROLE_CODE: {
            code: "U_007",
            message: "Invalid role code.",
            messageTh: "รหัส Role ไม่ถูกต้อง"
        }
    },
};

export const mapErrorCodeToString = (code: string): IErrorResponseDisplay | null => {
    for (const category in ERROR_RESPONSE) {
        const errors = (ERROR_RESPONSE as any)[category];
        for (const key in errors) {
            const error = errors[key];
            if (error.code === code) {
                return {
                    en: error.message,
                    th: error.messageTh
                };
            }
        }
    }
    return null; // if code is excluded from ERROR_RESPONSE , return null
}