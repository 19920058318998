import { arrayMove } from "@dnd-kit/sortable";
import {
    Box,
    Fab,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TextField,
    useTheme
} from "@mui/material";
import React, { useEffect, useState } from "react";
import '../../App.css';

import { Add } from "@mui/icons-material";
import { generateMockOrderData } from "../../configs/mock";
import { rxNumberCombination, rxNumberPermutation, rxSpace } from "../../configs/regex";
import { BetType } from "../../helpers/ticket_process/constants";
import { LotteryTicketNumberDto } from "../../helpers/ticket_process/dto/LotteryTicketNumberDto";
import { processLotteryTicketNumber } from "../../helpers/ticket_process/ticket_process.v2";
import gameService, { GameSession, OrderTicketRequestParams, TicketOrderNumber, TicketOrderRequestBody, TicketOrderRequestParams } from "../../services/game_service";
import { INumberDetail, ITicketData } from "../../services/type";
import { StyledFillButton } from "../../styled/filled_button";
import NumberInput from "../form/number_input";
import Spacer from "../spacing/spacer";
import { formatNumberString } from "../spring/string_helper";
import { sxCenter } from "../styled/styled_flex_container";
import OrderTableRow from "../table/draggable_table/static_table_row";
import { Column } from "../table/hoverable_table_row";
import '../table/ripple.css';
import PromptTypo from "../typography/typography_prompt";
import OrderTableAgentRateComponent from "./order_table_agent_rate";
import { useDialog } from "../transition_modal/dialog_provider";
import { SwalAlert, SwalCompleted, SwalLeaveConfirm } from "../dialog/swal_alert";
import { useSnackbar } from "../../stores/providers/snackbar_provider";
import { AgentRateDetails } from "../../services/rate_service";
import { PriceRateDto } from "../../helpers/ticket_process/dto/PriceRateDto";
import { SPACING_LG } from "../../configs/constants";
import { formatToCurrencyString } from "../../helpers/number_formatter_helper";

const rxOrderInputPattern = /^[0-9+\-*/._= ]*$/;
interface RowData {
    id: number;
    type: string;
    number: string;
    amount: string;
    summary?: string;
    betType?: BetType;
    betTypeEditted?: BetType;
    numberEditted?: string;
    valueEditted?: string;
    positionEditted?: string;
    isValid?: boolean | null;
    _isValid?: boolean | null;

}

interface AgentOrderTableComponentProps {
    gameSession?: GameSession;
    ownerId?: string | number;
    agentId?: string | number;
    ticketId?: string | number;
    closeCallback?: () => void;
    onLeave?: () => void;
}

export interface SnackbarMessage {
    message: string;
    key: number;
}


const AgentOrderTableComponent: React.FC<AgentOrderTableComponentProps> = ({
    gameSession, ownerId, agentId, ticketId, closeCallback, onLeave
}) => {
    const [ticketNumber, setTicketNumber] = useState<string | number | undefined>(1);
    const [snackPack, setSnackPack] = useState<readonly SnackbarMessage[]>([]);
    const [isRawEdited, setIsRawEdited] = useState<boolean>(false);
    const [isFormValid, setIsFormValid] = useState<boolean>(false);
    const [agentRate, setAgentRate] = useState<PriceRateDto | null | undefined>(undefined);
    const [ticketData, setTicketData] = useState<ITicketData | null>(null);
    const [isRowProcessValid, setRowProcessValid] = useState<boolean>(false);
    const { show } = useSnackbar();
    const [rows, setRows] = useState<RowData[]>([]);
    const [sumaryNumber, setSumaryNumber] = useState<any>();
    const [discountNumber, setDiscountNumber] = useState<any>(0);
    const [totalAmountNumber, setTotalAmountNumber] = useState<any>();
    const columns: Column[] = [
        { label: 'ลำดับ', field: 'id', render: (field) => field + 1 },
        { label: 'ประเภท', field: 'type', render: (field, row) => renderTypeFab(row) }, // Pass entire row to renderTypeFab
        { label: 'เลข', field: 'number', render: (field, row) => numberTextField(row) },
        { label: 'ราคา', field: 'amount', render: (field, row) => amountTextField(row) },
        { label: 'summary', field: 'formattedSummary', render: (field) => field },

    ];
    function createLotteryTicket(row: RowData, index: number): LotteryTicketNumberDto {
        return {
            numberRaw: row.number,
            valueRaw: row.amount,
            positionRaw: row.type,
            betTypeRaw: row.betType,
            betTypeEditedRaw: row.betTypeEditted,
            numberEditedRaw: row.numberEditted,
            valueEditedRaw: row.valueEditted,
            positionEditedRaw: row.positionEditted,
            rowIndex: index,
            _isEdited: undefined,
            _isValid: undefined,
            _betTypeRaw: undefined,
            _betTypeEditedRaw: undefined,
            _appliedNumbers: undefined,
            _totalAmount: undefined,
            _messages: undefined
        };
    }


    function processRow(row: RowData, index: number, forcedValidate?: boolean) {
        const lotteryTicket = createLotteryTicket(row, index);
        const processedTicket = processLotteryTicketNumber(lotteryTicket);

        const totalAmount = processedTicket._totalAmount ?? 0;
        const summaryMessage = processedTicket._messages?.join(", ") || "Valid";
        setRowProcessValid(processedTicket._isValid === true);
        return {
            ...row,
            summary: summaryMessage,
            summaryRowCurrency: summaryMessage === '' ? formatToCurrencyString(summaryMessage, 'en-US', { maximumFractionDigits: 0, minimumFractionDigits: 0 }) : '',
            isValid: determineValidity(row, processedTicket, forcedValidate),
            appliedNumbers: processedTicket._appliedNumbers,
            totalAmount
        };
    }

    function determineValidity(row: RowData, processedTicket: any, forcedValidate?: boolean) {
        if (forcedValidate === true || forcedValidate === false) {
            return forcedValidate;
        }
        return (row.number === '' && row.amount === '') ? true : processedTicket._isValid;
    }

    function formatSummary(totalAmount: number): string {
        const summaryToInteger = (Number(totalAmount) >= 0 ? Number(totalAmount) : 0) ?? '';
        return `${summaryToInteger}`;
    }

    function handleInputChange(id: number, field: keyof RowData, value: string, forcedValidate?: boolean) {
        if (value !== '') setIsRawEdited(true);
        setRows((prevRows) =>
            prevRows.map((row, index) => {
                if (row.id === id) {
                    const updatedRow = { ...row, [field]: value };
                    const processedRow = processRow(updatedRow, index, forcedValidate);
                    const summaryRow = (rows[index].number !== '' && rows[index].amount !== '') ? formatSummary(processedRow.totalAmount) : ''
                    const summaryRowCurrency = summaryRow ? formatToCurrencyString(summaryRow, 'en-US', { maximumFractionDigits: 0, minimumFractionDigits: 0 }) : '';
                    return {
                        ...processedRow,
                        summary: summaryRow,
                        formattedSummary: summaryRowCurrency
                    };
                }
                return row;
            })
        );
    }

    function handleOnFocusedOut(
        event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>,
        id: number,
        field: keyof RowData
    ) {
        const matchedRow = rows.find((row) => row.id === id);
        let value = event.target.value;
        let trimValue = value.trim();
        let newValue = trimValue.replace(rxSpace, '');
        let result = '';
        if (field === 'number') {
            const numberWithPermutationSign = newValue.match(rxNumberPermutation);
            result = numberWithPermutationSign ? numberWithPermutationSign[0] : '';
        }

        if (field === 'amount') {
            const numberWithCombinationSign = newValue.match(rxNumberCombination);
            result = numberWithCombinationSign ? numberWithCombinationSign[0] : '';
        }
        const isNumberEmpty = matchedRow?.number.trim() === "";
        const isAmountEmpty = matchedRow?.amount.trim() === "";
        if (isNumberEmpty && isAmountEmpty) {
            handleInputChange(id, 'number', result);
            handleInputChange(id, 'amount', result);
            return;
        }
        handleInputChange(id, field, result);
    }

    function removeEmptyRow(numbers: TicketOrderNumber[]) {
        return numbers.filter((f) => f.numberRaw !== '' && f.valueRaw !== '')
    }

    function getIsFormValid(numbers: TicketOrderNumber[]) {
        return !rows.find((f => f.isValid === false));
    }

    const handleSubmitTicketOrder = async () => {
        const tempNumber = rows.map(((row, index) => {
            return {
                numberRaw: `${row.number}`,
                valueRaw: `${row.amount}`,
                positionRaw: `${row.type}`,
                betType: `${row.betType}`,
                rowIndex: index,
                isValid: row.isValid === true
            }
        }));
        const reqBody: TicketOrderRequestBody = {
            sequence: `${ticketNumber ?? 1}`,
            numbers: removeEmptyRow(tempNumber)
        }
        const reqParams: TicketOrderRequestParams = {
            gameId: `${gameSession!?.id}`,
            agentId: `${agentId}`,
        }
        if (!getIsFormValid(reqBody.numbers)) {
            show('กรุณากรอกข้อมูลให้ถูกต้อง', true)

            return;
        }
        if (ticketId) {
            await gameService.updateTicketOrder(reqBody, { ...reqParams, ticketId: `${ticketId}` });
            closeCallback?.();
        } else {
            await gameService.createTicketOrder(reqBody, reqParams);
            closeCallback?.();
        }
    }

    const fetchTicketNewOrder = async () => {
        const params: OrderTicketRequestParams = {
            gameId: `${gameSession?.id}`,
            agentId: `${agentId}`,
        }
        await gameService.getTicketOrderByNewTicket(params).then((res) => {
            setAgentRate(undefined);
            const data = res?.data;
            setTicketData(res?.data ?? null)
            setTicketNumber(data?.sequence ?? 1);
            setAgentRate(data?.priceRates ?? null);
        }).catch((_) => {
            setTicketData(null);
        })
    }

    const fetchTicketIdOrder = async () => {
        const params: OrderTicketRequestParams = {
            gameId: `${gameSession?.id}`,
            agentId: `${agentId}`,
            ticketId: `${ticketId}`
        }

        try {
            setAgentRate(undefined);
            gameService.getTicketOrderByTicketId(params).then((res) => {
                const data = res?.data;
                mapRawDataToForm(data!);
                setAgentRate(data?.priceRates ?? null);
            })
        } catch (error) { }
    }
    function mapRawDataToForm(res: ITicketData) {
        const nums = res?.numbers;
        if (!nums?.length) return;

        try {
            const tempRows: RowData[] = nums.map((num, index) => {
                const updatedRow: RowData = {
                    id: num.rowIndex,
                    type: num.positionRaw,
                    number: num.numberRaw,
                    amount: num.valueRaw,
                    // betType: num.betTypeRaw,
                };

                const processedRow = processRow(updatedRow, index);
                const summaryRow = formatSummary(processedRow.totalAmount);
                const summaryRowCurrency = summaryRow ? formatToCurrencyString(summaryRow, 'en-US', { maximumFractionDigits: 0, minimumFractionDigits: 0 }) : '';
                return {
                    ...processedRow,
                    summary: summaryRow,
                    formattedSummary: summaryRowCurrency
                };
            });
            setRows(tempRows);
            setTicketNumber(res?.sequence ?? 1);
        } catch (error) {
            console.error("Error in mapRawDataToForm:", error);
        }
    }

    useEffect(() => {
        const totalAmount = rows.reduce((sum, row) => sum + (Number(row.summary) || 0), 0);
        setSumaryNumber(totalAmount);
    }, [rows])

    // Function to add 5 new rows
    function addRows(): void {
        setRows((prevRows) => {
            const newRows: RowData[] = [];
            const startId = prevRows.length + 1; // Determine the starting ID for new rows
            for (let i = 0; i < 10; i++) {
                newRows.push({ id: startId + i, type: "บ", number: "", amount: "", isValid: null });
            }
            return [...prevRows, ...newRows];
        });
    }

    const toggleType = (id: number) => {
        rows.map((row) => {
            if (row.id === id) {
                handleInputChange(id, "type", row.type === "บ" ? "ล" : row.type === "ล" ? "บ+ล" : "บ");
            }
        })
    };

    const muiTheme = useTheme();

    const handleDragEnd = (event: any) => {
        const { active, over } = event;
        if (active.id !== over?.id) {
            setRows((prevRows) => {
                const oldIndex = prevRows.findIndex((row) => row.id === active.id);
                const newIndex = prevRows.findIndex((row) => row.id === over?.id);
                return arrayMove(prevRows, oldIndex, newIndex);
            });
        }
    };

    const typeColorTransform = (type: string) => {
        if (type === 'บ') return <PromptTypo fontWeight='bold'>บ</PromptTypo>
        else if (type === 'ล') return <PromptTypo fontWeight='bold' color={muiTheme.palette.error.main}>ล</PromptTypo>
        else if (type === 'บ+ล') return <Stack direction={'row'}>
            <PromptTypo fontWeight='bold'>บ</PromptTypo>
            <PromptTypo fontWeight='bold' color={muiTheme.palette.text.secondary}>+</PromptTypo>
            <PromptTypo fontWeight='bold' color={muiTheme.palette.error.main}>ล</PromptTypo>
        </Stack>
    }

    const renderTypeFab = (row: RowData) => (
        <Fab
            disabled={!agentRate}
            tabIndex={-1}
            onClick={() => {
                toggleType(row.id); // Call toggleType with the row's id
            }}
            sx={{
                bgcolor: muiTheme.palette.background.paper,
                color: muiTheme.palette.secondary.light,
                ":hover": { bgcolor: muiTheme.palette.background.default }
            }}
            size="small"
        >
            {typeColorTransform(row.type)}
        </Fab>
    );

    const orderTransform = (value: string): string => {
        const lastestText = value[value.length - 1];
        const isLastTextNumber = !isNaN(Number(lastestText));

        if (isLastTextNumber || lastestText === '0') {
            return value;
        }
        if (lastestText === ' ') {
            return '';
        }
        const symbolMap: { [key: string]: string } = {
            '*': '*',
            '-': '-',
            'x': '*',
            ' ': '',
        };
        return value.slice(0, -1) + (symbolMap[lastestText] || '');
    };

    const numberTextField = (row: RowData) => <TextField
        sx={{
            minWidth: "100px",
        }}
        autoComplete="off"
        variant="outlined"
        size="small"
        value={row.number}
        onClick={(e) => e.stopPropagation()}
        disabled={!agentRate}
        onBlur={(e) => {
            handleOnFocusedOut(e, row.id, 'number');
        }}
        onChange={(e) => {
            let newValue = e.target.value;
            if (newValue.endsWith(' ')) {
                toggleType(row.id)
                newValue = e.target.value.trim();
            }
            const transformedValue = orderTransform(newValue);
            handleInputChange(row.id, "number", transformedValue);
        }}
    />

    const amountTextField = (row: RowData) => <TextField
        sx={{
            minWidth: "100px",
        }}
        autoComplete="off"
        variant="outlined"
        disabled={!agentRate}
        size="small"
        value={row.amount}
        onClick={(e) => e.stopPropagation()}
        onBlur={(e) => {
            handleOnFocusedOut(e, row.id, 'amount');
        }}
        onChange={(e) => {
            let newValue = e.target.value;
            if (newValue.endsWith(' ')) {
                toggleType(row.id)
                newValue = e.target.value.trim();
            }
            const transformedValue = orderTransform(newValue);
            handleInputChange(row.id, "amount", transformedValue);
        }}
    />

    const chunkRows = (rows: any[], chunkSize: number): any[][] => {
        const result: any[][] = [];
        for (let i = 0; i < rows.length; i += chunkSize) {
            result.push(rows.slice(i, i + chunkSize));
        }
        return result;
    };
    const chunkedRows = chunkRows(rows, 10000);

    const renderRateTable = () => {
        return <Box>
            <Stack
                direction="row"
                spacing={0}
                sx={{
                    flexWrap: "nowrap",
                    alignItems: "center", justifyItems: 'space-between',
                    padding: 1
                }}>
                <PromptTypo width={'150px'}>โพยใบที่</PromptTypo>
                <NumberInput
                    onNumberDecreased={() => setTicketNumber((prev) => (Number(prev) - 1))}
                    onNumberIncreased={() => setTicketNumber((prev) => (Number(prev) + 1))}
                    onNumberChanged={(number) => setTicketNumber(number)}
                    value={Number(ticketNumber)} />
            </Stack>
            {
                <OrderTableAgentRateComponent
                    priceRate={agentRate}
                    summaryPrice={sumaryNumber}
                    gameSession={gameSession} ownerId={ownerId} agentId={agentId} />}
        </Box>
    }


    const renderTicketOrderTable = () => {
        return (<Box sx={{
            width: '100%',
            margin: 0,
            padding: 0
        }}>
            {chunkedRows.map((chunk, index) => (
                <Paper sx={{ ...sxCenter, width: '100%', paddingRight: SPACING_LG, paddingLeft: SPACING_LG }}>
                    <OrderTableRow rows={chunk} columns={columns} />
                    <Fab sx={{ position: 'absolute', zIndex: 999, bottom: 50, right: 50 }}
                        onClick={addRows}
                    >
                        <Add />
                    </Fab>
                </Paper>
            ))
            }
        </Box >)
    }

    const renderTicketSummary = () => {
        return (
            <TableContainer
                component={Paper}
                sx={{
                    minHeight: '150px'
                }}
            >
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell>ยอดซื้อ</TableCell>
                            <TableCell align="right">
                                <strong>
                                    {formatNumberString(`${sumaryNumber ?? 0}`)} บาท
                                </strong>
                            </TableCell>
                        </TableRow>
                        {ticketId && <TableRow>
                            <TableCell>ส่วนลด</TableCell>
                            <TableCell sx={{ color: muiTheme.palette.error.light }}
                                align="right">
                                <strong>
                                    {formatNumberString(`${discountNumber ?? 0}`)} บาท
                                </strong>
                            </TableCell>
                        </TableRow>}
                        <TableRow>
                            <TableCell><strong>ยอดรวม</strong></TableCell>
                            <TableCell sx={{ color: muiTheme.palette.secondary.light }}
                                align="right">
                                <strong>
                                    {formatNumberString(`${totalAmountNumber ?? 0}`)} บาท
                                </strong>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    useEffect(() => {
        setTotalAmountNumber(sumaryNumber - discountNumber);
    }, [sumaryNumber, discountNumber])

    useEffect(() => {
        setRows(generateMockOrderData(20))
        if (ticketId) fetchTicketIdOrder();
        else fetchTicketNewOrder();
    }, []);

    useEffect(() => {
        const isValid = rows.every((row) => {
            const isEmpty = row.number === "" && row.amount === "";
            const isFilledCorrectly = row.number !== "" && row.amount !== "";

            return isEmpty || (isFilledCorrectly && row.isValid === true);
        });

        setIsFormValid(isValid);
    }, [rows]);
    if (agentRate === null) {
        // onLeave?.();
    }
    return (
        <Stack
            direction={{ md: "column", lg: "row" }}
            sx={{
                overflowY: { lg: 'hidden', md: 'auto' },
                height: { lg: '85vh', md: 'auto' },
                bgcolor: muiTheme.palette.background.paper,
            }}>
            <Box
                sx={{
                    width: { xs: "100%", md: "100%", lg: "30%" },
                    p: 2,
                }}>
                {renderRateTable()}
            </Box>
            <Box
                sx={{
                    height: { lg: '85vh', md: 'auto' },
                    minHeight: '500px',
                    width: { xs: "100%", md: "100%", lg: "40%" },
                    overflowY: "auto",
                    p: 2,
                }}>
                <Box sx={{ height: "auto" }}>
                    {renderTicketOrderTable()}
                </Box>
            </Box>
            <Box
                sx={{
                    width: { xs: "100%", md: "100%", lg: "30%" },
                    p: 2,
                }}>
                {renderTicketSummary()}
                <Spacer size="lg" />
                <StyledFillButton
                    fullWidth
                    sx={{
                        color: muiTheme.palette.common.white,
                        bgcolor: muiTheme.palette.secondary.main,
                        ":hover": { color: muiTheme.palette.secondary.light }
                    }}
                    onClick={handleSubmitTicketOrder}
                    disabled={!isFormValid || !agentRate || isRawEdited === false}
                >
                    บันทึก
                </StyledFillButton>
                <Spacer size='md' />
                <StyledFillButton
                    fullWidth
                    sx={{
                        color: muiTheme.palette.common.white,
                        bgcolor: `${muiTheme.palette.error.main}`,
                        ":hover": {
                            color: muiTheme.palette.error.light,
                            bgcolor: `${muiTheme.palette.error.dark}35`,
                        }
                    }}
                    onClick={
                        () => SwalLeaveConfirm(
                            () => () => handleSubmitTicketOrder, // บันทึกและออก
                            () => onLeave?.(), //ออกโดยไม่บันทึก
                            () => null //ยกเลิก
                        )
                    }>
                    ยกเลิก
                </StyledFillButton>
            </Box>
        </Stack >
    );
};

export default AgentOrderTableComponent;
