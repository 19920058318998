import { AssignmentReturn, Check, Close, Download, Edit, FileCopy, SyncAlt } from '@mui/icons-material';
import { Fab, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import '../../src/components/table/ripple.css';
import DialogService from '../components/dialog/confirmation_by_sweet_alert2';
import RateAdjustmentDialog from '../components/dialog/rate_adjustment_dialog';
import { SwalAlert, SwalCompleted } from '../components/dialog/swal_alert';
import AppPagination from '../components/pagination/app_pagination';
import Spacer from '../components/spacing/spacer';
import { StyledContainer } from '../components/styled/styled_flex_container';
import TableContentEllipsisText from '../components/table/table_content_ellipsis';
import { colorScheme, deepGoldPalette } from '../components/theme/colors';
import PromptTypo from '../components/typography/typography_prompt';
import { MOCK_AGENT_RATE_DATA } from '../configs/mock';
import rateService, { AgentCodeListRequestParams, AgentRateDetails } from '../services/rate_service';
import { defaultPaginationParams, defaultResponseMetaData, PaginationRequestParams, ResponseMetaData } from '../services/type';
import { useAppSettings } from '../stores/context/app_setting_context';
import { useAuth } from '../stores/providers/auth_provider';
import { useDialog } from '../stores/providers/dialog_provider';
import { useSnackbar } from '../stores/providers/snackbar_provider';
import CustomizedInputBase from '../styled/custom_input_based';
import { StyledOutlineButton } from '../styled/filled_button';
import zIndex from '@mui/material/styles/zIndex';
interface MemberData {
    memberId: string;
    name: string;
    prices: number[];
    reductions: number[];
}

const MemberRatePate: React.FC = () => {
    const { state } = useAppSettings();
    const [data, setData] = useState(MOCK_AGENT_RATE_DATA);
    const [filter, setFilter] = useState<PaginationRequestParams<AgentCodeListRequestParams>>(defaultPaginationParams);
    const [meta, setMeta] = useState<ResponseMetaData>(defaultResponseMetaData);
    const [openAdjustment, setOpenAdjustment] = useState(false);
    const [copied, setCopied] = useState<AgentRateDetails | null>(null);
    const [dialogId, setDialogId] = useState<number | null>();
    const [hoveringIndex, setHoveringIndex] = useState<number | null>();
    const [highlightedRows, setHighlightedRows] = useState<Set<number | undefined>>(new Set());
    const [hightlightedCopied, setHightlightedCopied] = useState<string | null>();
    const { openDialog, closeDialog } = useDialog();
    const { show } = useSnackbar();
    const { user } = useAuth();
    const paperColor = state.theme.palette.background.paper;
    const tableColorStage = {
        defaultBg: paperColor,
        tableContentBg: paperColor,
        cardBg: state.theme.palette.divider,
        tableEvenContentBg: state.theme.palette.action.active,
        stickyTopBg: `${state.theme.palette.info.main}30`,
        tableContentBgHovered: state.theme.palette.action.active,
        tableDefaultTextColor: state.theme.typography.body2.color,
        tableHighlightedTextColor: state.theme.palette.primary.main,
        tableActionMain: state.theme.palette.secondary.main,
        tableActionOptional: state.theme.palette.action.selected,
        hoverColor: `${colorScheme.bgColor}20`
    }

    const handlePageChange = (page: number) => {
        setFilter((prevFilter) => ({ ...prevFilter, page }));
    };

    const handleOnChangeFilter = (newData: AgentCodeListRequestParams) => {
        setFilter((prevFilter) => ({ ...prevFilter, ...newData }));
    };

    const handleSearch = (withSwalComp = false) => {
        if (filter.page !== 1)
            setFilter((prevFilter) => ({ ...prevFilter, page: 1 }));
        else {
            fetchRates(withSwalComp);
        }
    };

    const fetchRates = async (withSwalComp = false) => {
        if (!user.ownerId) return;
        try {
            const response = await rateService.listOwnerRates(user.ownerId, filter);
            setData(response?.data ?? []);
            setMeta(response?.meta ?? {})
            if (withSwalComp) {
                SwalCompleted();
            }
        } catch (error) {

        }
    }

    const stickyStyles = (bgColor: string, { left = 0, right = 0 }) => {
        return {
            position: "sticky",
            overflow: 'hidden',
            zIndex: 9,
            left: left,
            right: right,
            bgCo: `${bgColor}`,
            padding: "8px",
            cursor: "pointer",
            fontWeight: 'bold'

        }
    }

    const handleEdit = (row: AgentRateDetails) => {
        console.log('HANDLE EDIT', row);
        setOpenAdjustment(prevOpen => !prevOpen);
        setCopied(null);
        const targetAgent = JSON.parse(JSON.stringify({ agentId: row.agentId, agentCode: row.agentCode }));
        const { agentId, agentCode } = targetAgent;
        const dialogId = openDialog(
            <RateAdjustmentDialog
                selectedAgentId={`${row.agentId}`}
                onClose={handleClose}
                data={row}
                onSubmit={(data) => handlePasteOrUpdate({
                    agentCode,
                    agentId,
                }, data,
                )}
                mode={'EDIT'} />, 'sm')
        setDialogId(dialogId);
    }

    const handleCopy = (row: AgentRateDetails) => {
        const newHighlightedRows = new Set(data.map(item => item.id));
        setHighlightedRows(newHighlightedRows);
        setTimeout(() => {
            setCopied(row);
        }, 1);
        show('คัดลอกสำเร็จ')
    };

    const handlePasteOrUpdate = ({ agentId = '', agentCode = '' }, newData?: Partial<AgentRateDetails>) => {
        const displayText = newData ? 'แก้ไข' : 'คัดลอก';

        closeDialog();
        if (agentId === null || agentId === undefined) return;
        if (!!copied || !!newData) {
            const options = DialogService.confirmation(
                `ยืนยันการ${displayText}ข้อมูล`,
                newData ? `คุณแน่ใจที่จะแก้ไขมูลของ Agent: ${agentCode} หรือไม่ ?`
                    : `คุณแน่ใจที่จะคัดลอกข้อมูลจาก Agent: ${copied?.agentCode} ไปยัง Agent: ${agentCode} หรือไม่ ?`
            );
            DialogService.open(options, () => {
                if (!user.ownerId) return;
                closeDialog(dialogId);
                try {
                    rateService.updateOwnerRates(user.ownerId, +agentId, !!copied ? copied! : newData!).then((res) => {
                        if (res.message) {
                            SwalAlert({ title: "ทำรายการไม่สำเร็จ", description: `${res.message}` })
                            handlePageChange(meta.page ?? 1);
                        }
                        else {
                            fetchRates(true);
                            // SwalCompleted().then(() =>
                            //     fetchRates(true)
                            //     // window.location.reload()
                            // );
                        }
                    })
                }
                catch (error) {
                    setCopied(null);
                    SwalAlert({ title: "ทำรายการไม่สำเร็จ", description: `${error}` })
                }
            });
            setCopied(null);
        } else {
            show('ไม่มีข้อมูลที่คัดลอก');
            setCopied(null);
        }
    };

    const handleSubmit = ({ agentId = '', agentCode = '' }, data: Partial<AgentRateDetails>) => {
        if (!user.ownerId) return;
        handlePasteOrUpdate({ agentId, agentCode }, data)
    }

    const handleClose = () => {
        closeDialog();
    }
    const primaryColor = state.theme.palette.primary.main;
    const secondaryColor = state.theme.palette.secondary.main;
    const focusColor = state.theme.palette.action.focus;

    useEffect(() => {
        fetchRates();
    }, [filter.page]);

    const speeddialActions = (row: AgentRateDetails, i?: number, alwayShow = false) => {
        const copiedMatchingRow = ((!!copied && copied?.id === row.id));
        const canPaste = ((copied && !copiedMatchingRow));
        return (
            ((hoveringIndex === i) || copiedMatchingRow) && <Stack
                direction="row"
                justifyItems={'flex-start'}
                alignItems={'center'}
                sx={{
                    '& > :not(style)': { m: 1 }
                }}
            >
                <Tooltip placement='top' title={canPaste ? 'กดเพื่อวาง' : 'แก้ไข'}>
                    <Fab
                        size='small'
                        variant="extended"
                        color="secondary"
                        aria-label="adjust"
                        sx={{
                            fontWeight: 'bold',
                            minWidth: '100px',
                            visibility: copiedMatchingRow ? 'hidden' : 'visible',
                            opacity: canPaste && copiedMatchingRow ? 0 : 1,
                            bgcolor: colorScheme.goldTierLightBg,
                            color: colorScheme.darkBGColor,
                            ":hover": { color: colorScheme.textColor },
                            ...(canPaste && {
                                bgcolor: colorScheme.goldTierLightBg,
                                color: colorScheme.darkBGColor,
                            })
                        }}
                        onClick={() => {
                            if (canPaste)
                                handlePasteOrUpdate({ agentId: JSON.stringify(row.agentId), agentCode: row?.agentCode ?? undefined as any });
                            else {
                                (!!i || i === 0) && handleEdit({ ...data[i] });
                            }
                        }} >
                        {canPaste && <AssignmentReturn />}
                        {!canPaste && <Edit />}
                        <Spacer size='xs' />
                        <PromptTypo sx={{ fontWeight: 'bold' }}>{canPaste ? 'วาง' : 'แก้ไข'}</PromptTypo>
                    </Fab>
                </Tooltip>
                <Tooltip placement='top' title={copiedMatchingRow ? '' : 'กดเพื่อคัดลอก'}>
                    < Fab
                        size='small'
                        variant="extended"
                        color="secondary"
                        aria-label="add"
                        sx={{
                            fontWeight: 'bold',
                            minWidth: '120px',
                            ...copiedMatchingRow &&
                            { bgcolor: `${colorScheme.subText}` }
                        }}
                        onClick={() => handleCopy(row)}
                        disabled={copiedMatchingRow}
                    >
                        {copiedMatchingRow && <Check
                            color='info'
                            sx={{
                                fontWeight: 'bold',
                            }} />}
                        {!copiedMatchingRow && <FileCopy />}
                        <Spacer size='xs' />
                        <PromptTypo
                            sx={{
                                fontWeight: 'bold',
                                ...copiedMatchingRow &&
                                { color: `${tableColorStage.tableDefaultTextColor}` }
                            }}
                            variant='subtitle1' >{`คัดลอก${copiedMatchingRow ? 'แล้ว' : ''}`}</PromptTypo>
                    </Fab>
                </Tooltip>
            </Stack >
        );
    };

    useEffect(() => {
        if (copied) {
            setHightlightedCopied(`${copied.id}`);
            const timer = setTimeout(() => {
                setHightlightedCopied(null);
            }, 500);
            return () => clearTimeout(timer);
        }
    }, [copied]);

    const getRowStyle = (rowId: string) => {
        if (hightlightedCopied === rowId) {
            return {
                backgroundColor: `${tableColorStage.cardBg}`,
                transition: 'background-color 100ms ease-in-out',
                animationFillMode: 'forwards',
            };
        }
        return {
            backgroundColor: tableColorStage.hoverColor,
            transition: 'background-color 100ms ease-in-out',
        };
    };

    const tableRow = (row: AgentRateDetails, index?: number, isSticky: boolean = false) => {
        const copiedMatchingRow = !!copied && copied?.id === row.id;
        const canPaste = (copied && !copiedMatchingRow)
        const isHovering = hoveringIndex === index;

        return (
            <TableRow
                hover
                draggable
                onMouseOver={() => setHoveringIndex(copiedMatchingRow ? null : index)}
                onMouseLeave={() => setHoveringIndex(null)}
                component={Paper}
                className={isSticky ? "wave-animation" : ''}
                key={index}
                onClick={(e) => {
                    const target = e.currentTarget;
                }}
                sx={{
                    bgcolor: (index ?? 0) % 2 === 0 ? `${tableColorStage.tableActionMain}15` : '',
                    position: 'relative',
                    overflow: 'hidden',
                    ":hover": {
                        cursor: 'pointer',
                    },
                    ...(isSticky && {
                        position: 'sticky',
                        top: 0,
                        zIndex: 1,
                        ...getRowStyle(`${row.id}`),
                    }),
                }}
            >
                <TableCell sx={
                    {
                        ...stickyStyles('', { left: 0 }),
                        color: primaryColor,
                        bgcolor: isSticky ? tableColorStage.defaultBg : isHovering ? tableColorStage.tableContentBgHovered : tableColorStage.defaultBg,
                        fontWeight: 'bold'
                    }
                }>
                    <TableContentEllipsisText text={`${row?.agentCode ?? '-'}`} />
                </TableCell>
                <TableCell>
                    <TableContentEllipsisText text={(!!row.agentFirstname && !!row.agentLastname) ? `${row.agentFirstname} ${row.agentLastname}` : '-'} />
                </TableCell>
                {/* 3 บน */}
                <TableCell align="right">{row.threeUpperStraightPrice ?? '-'}</TableCell>
                <TableCell align="right">{row.threeUpperStraightPercent ?? '-'}</TableCell>

                {/* 3 โต๊ด	 */}
                <TableCell align="right">{row.threeShufflePrice ?? '-'}</TableCell>
                <TableCell align="right">{row.threeShufflePercent ?? '-'}</TableCell>

                {/* 2 บน */}
                <TableCell align="right">{row.twoUpperStraightPrice ?? '-'}</TableCell>
                <TableCell align="right">{row.twoUpperStraightPercent ?? '-'}</TableCell>

                {/* 2 โต๊ด */}
                <TableCell align="right">{row.twoShufflePrice ?? '-'}</TableCell>
                <TableCell align="right">{row.twoShufflePercent ?? '-'}</TableCell>

                {/* วิ่ง บน */}
                <TableCell align="right">{row.upperRunningPrice ?? '-'}</TableCell>
                <TableCell align="right">{row.upperRunningPercent ?? '-'}</TableCell>

                {/* 3 ล่าง */}
                <TableCell align="right">{row.threeLowerStraightPrice ?? '-'}</TableCell>
                <TableCell align="right">{row.threeLowerStraightPercent ?? '-'}</TableCell>

                {/* 2 ล่าง */}
                <TableCell align="right">{row.twoLowerStraightPrice ?? '-'}</TableCell>
                <TableCell align="right">{row.twoLowerStraightPercent ?? '-'}</TableCell>

                {/* วิ่ง ล่าง */}
                <TableCell align="right">{row.lowerRunningPrice ?? '-'}</TableCell>
                <TableCell align="right">{row.lowerRunningPercent ?? '-'}</TableCell>

                {/* ลอยแพ 4 ตัว */}
                <TableCell align="right">{row.fourFloatingPrice ?? '-'}</TableCell>
                <TableCell align="right">{row.fourFloatingPercent ?? '-'}</TableCell>

                {/* ลอยแพ 5 ตัว */}
                <TableCell align="right">{row.fiveFloatingPrice ?? '-'}</TableCell>
                <TableCell align="right">{row.fiveFloatingPercent ?? '-'}</TableCell>
                <TableCell size='small'
                    sx={{
                        backgroundColor: isSticky ? tableColorStage.defaultBg : '',
                        ...stickyStyles(state.theme.palette.background.paper, { right: 0 }),
                    }}
                >
                    <Stack direction={'row'} sx={{
                        justifyContent: 'flex-start', alignItems: 'center', width: '250px',
                        bgcolor: ''
                    }}>
                        {!isSticky && speeddialActions(row, index, true)}
                        {isSticky && <PromptTypo
                            sx={{ fontWeight: 'bold' }}>
                            ข้อมูลที่ถูกคัดลอก
                        </PromptTypo>}
                        {isSticky && <Spacer size='lg' />}
                        {isSticky && <Fab
                            size='small'
                            variant='extended'
                            sx={{
                                backgroundColor: 'transparent',
                                fontWeight: 'bold',
                                overflow: 'hidden',
                                bgcolor: colorScheme.red75, color: state.theme.palette.common.white,
                                fontSize: '14px',
                                ":hover": {
                                    backgroundColor: colorScheme.red100,
                                    color: state.theme.palette.common.white,
                                }
                            }} onClick={() => setCopied(null)}>
                            <Close />
                            <Spacer size='xs' />
                            <PromptTypo
                                sx={{ fontWeight: 'bold' }}>
                                ล้างข้อมูล
                            </PromptTypo>
                        </Fab>}
                    </Stack>
                </TableCell>

            </TableRow >
        )
    }

    <TableBody  >
        {data.map((row, index) => (
            tableRow(row, index)
        ))}
    </TableBody >

    return (
        <StyledContainer>
            <PromptTypo variant="h5">ราคาสมาชิก</PromptTypo>
            <Spacer size='md' />
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    handleSearch();
                }}
            >
                <Stack direction="row" justifyContent='flex-start' alignItems='center'>
                    <CustomizedInputBase
                        searchIcon

                        placeholder="ค้นหาชื่อ Agent"
                        onSubmit={(e: any) => {
                            e.preventDefault();
                            handleSearch();
                        }}
                        onChange={(value: string) => handleOnChangeFilter({ name: value })}
                    />
                    <Spacer size="md" />
                    <CustomizedInputBase
                        searchIcon

                        placeholder="ค้นหารหัส Agent"
                        onSubmit={(e: any) => {
                            e.preventDefault();
                            handleSearch();
                        }}
                        onChange={(value: string) => handleOnChangeFilter({ agentCode: value })}
                    />
                    <Spacer size="md" />
                    <StyledOutlineButton sx={{ height: '37px' }} type="submit">ค้นหา</StyledOutlineButton>
                </Stack>
            </form>
            <Spacer size='md' />
            <TableContainer sx={{ width: '100vw - 240px' }}>
                <Table>
                    {tableHeader()}
                    <TableBody>
                        {copied && (
                            tableRow(copied, 0, true)
                        )}
                        {data.map((row, index) => (
                            tableRow(row, index)

                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <AppPagination
                page={filter.page ?? 1}
                count={meta.totalPages ?? 1}
                onPageChange={handlePageChange} />
        </StyledContainer>
    );

    function tableHeader() {
        return <TableHead sx={{ backgroundColor: paperColor }}>
            <TableRow>
                <TableCell rowSpan={2} sx={{
                    backgroundColor: state.theme.palette.background.paper,
                    ...stickyStyles(state.theme.palette.background.paper, { left: 0 }),
                    width: 130
                }}>รหัสสมาชิก</TableCell>
                <TableCell rowSpan={2} sx={{ width: 130 }}>ชื่อ</TableCell>
                <TableCell colSpan={2} align="center">3 บน</TableCell>
                <TableCell colSpan={2} align="center">3 โต๊ด</TableCell>
                <TableCell colSpan={2} align="center">2 บน</TableCell>
                <TableCell colSpan={2} align="center">2 โต๊ด</TableCell>
                <TableCell colSpan={2} align="center">วิ่ง บน</TableCell>
                <TableCell colSpan={2} align="center">3 ล่าง</TableCell>
                <TableCell colSpan={2} align="center">2 ล่าง</TableCell>
                <TableCell colSpan={2} align="center">วิ่ง ล่าง</TableCell>
                <TableCell colSpan={2} align="center">ลอยแพ 4 ตัว</TableCell>
                <TableCell colSpan={2} align="center">ลอยแพ 5 ตัว</TableCell>
                <TableCell align="right"></TableCell>
            </TableRow>

            <TableRow>
                <TableCell align="right">ราคา</TableCell>
                <TableCell align="right">ลด %</TableCell>
                <TableCell align="right">ราคา</TableCell>
                <TableCell align="right">ลด %</TableCell>
                <TableCell align="right">ราคา</TableCell>
                <TableCell align="right">ลด %</TableCell>
                <TableCell align="right">ราคา</TableCell>
                <TableCell align="right">ลด %</TableCell>
                <TableCell align="right">ราคา</TableCell>
                <TableCell align="right">ลด %</TableCell>
                <TableCell align="right">ราคา</TableCell>
                <TableCell align="right">ลด %</TableCell>
                <TableCell align="right">ราคา</TableCell>
                <TableCell align="right">ลด %</TableCell>
                <TableCell align="right">ราคา</TableCell>
                <TableCell align="right">ลด %</TableCell>
                <TableCell align="right">ราคา</TableCell>
                <TableCell align="right">ลด %</TableCell>
                <TableCell align="right">ราคา</TableCell>
                <TableCell align="right">ลด %</TableCell>
                <TableCell sx={stickyStyles('transparent', { right: 0 })} align="right"></TableCell>
            </TableRow>
        </TableHead>;
    }
};

export default MemberRatePate;
