import { AppFormData } from "../components/form/form_builder"
import { rxPassword } from "./regex"
import { renderNumberWithCurrencyString, renderValueIfNotEmpty } from "./functions"
import { formatToCurrencyString } from "../helpers/number_formatter_helper"
import { Column } from "../components/table/hoverable_table_row"
import dayjs from "dayjs"

export const appConstants = {}

export const regs = {
    phoneString: new RegExp('/D/g'),
}

export const USER_TYPE = {
    MEMBER: 'member',
    EMPLOYEE: 'employee',
}

export const USER_ROLE = {
    "SUPER_ADMIN": "SA",
    "OWNER": "W",
    "STAFF": "S",
    "AGENT": "A"
}
export const USER_ROLE_NAME_FROM_CODE = {
    "SA": "Super Admin",
    "W": "Owner",
    "S": "Staff",
    "A": "Agent"
}

export const USER_ROLE_NAME = {
    [USER_ROLE["SUPER_ADMIN"]]: "Super Admin",
    [USER_ROLE["OWNER"]]: "Owner",
    [USER_ROLE["STAFF"]]: "Staff",
    [USER_ROLE["AGENT"]]: "Agent"
}

export const STORAGE_KEY = {
    ACCESS_TOKEN: "ACCESS_TOKEN",
    REFRESH_TOKEN: "REFRESH_TOKEN",
    USER: "USER",
}

export enum Role {
    SUPER_ADMIN = 'SUPER_ADMIN',
    OWNER = 'OWNER',
    STAFF = 'STAFF',
    AGENT = 'AGENT'
}
export enum RoleCode {
    SUPER_ADMIN = 'SA',
    OWNER = 'W',
    STAFF = 'S',
    AGENT = 'A'
}

export enum Permission {
    USER_CREATE_AGENT = 'USER_CREATE_AGENT',
    USER_CREATE_OWNER = 'USER_CREATE_OWNER',
    USER_CREATE_STAFF = 'USER_CREATE_STAFF',
    USER_EDIT_AGENT = 'USER_EDIT_AGENT',
    USER_EDIT_OWNER = 'USER_EDIT_OWNER',
    USER_EDIT_STAFF = 'USER_EDIT_STAFF',
    USER_LIST = 'USER_LIST',
    USER_LIST_AGENT = 'USER_LIST_AGENT',
    USER_LIST_OWNER = 'USER_LIST_OWNER',
    USER_LIST_STAFF = 'USER_LIST_STAFF',
    USER_PERMISSION_AGENT = 'USER_PERMISSION_AGENT',
    USER_PERMISSION_OWNER = 'USER_PERMISSION_OWNER',
    USER_PERMISSION_STAFF = 'USER_PERMISSION_STAFF',
    USER_PROFILE_CHANGE_PASSWORD = 'USER_PROFILE_CHANGE_PASSWORD',
    USER_PROFILE_EDIT = 'USER_PROFILE_EDIT',
}

export const {
    USER_CREATE_AGENT,
    USER_CREATE_OWNER,
    USER_CREATE_STAFF,
    USER_EDIT_AGENT,
    USER_EDIT_OWNER,
    USER_EDIT_STAFF,
    USER_LIST,
    USER_LIST_AGENT,
    USER_LIST_OWNER,
    USER_LIST_STAFF,
    USER_PERMISSION_AGENT,
    USER_PERMISSION_OWNER,
    USER_PERMISSION_STAFF,
    USER_PROFILE_CHANGE_PASSWORD,
    USER_PROFILE_EDIT,
} = Permission;

const rolePermissions: Record<Role, Permission[]> = {
    [Role.SUPER_ADMIN]: [
        USER_CREATE_AGENT,
        USER_CREATE_OWNER,
        USER_CREATE_STAFF,
        USER_EDIT_AGENT,
        USER_EDIT_OWNER,
        USER_EDIT_STAFF,
        USER_LIST,
        USER_LIST_AGENT,
        USER_LIST_OWNER,
        USER_LIST_STAFF,
        USER_PERMISSION_AGENT,
        USER_PERMISSION_OWNER,
        USER_PERMISSION_STAFF,
        USER_PROFILE_CHANGE_PASSWORD,
        USER_PROFILE_EDIT,
    ],
    [Role.OWNER]: [
        USER_LIST_AGENT,
        USER_LIST_STAFF,
        USER_EDIT_AGENT,
        USER_EDIT_STAFF,
        USER_CREATE_AGENT,
        USER_CREATE_STAFF,
        USER_PERMISSION_AGENT,
        USER_PERMISSION_STAFF,
        USER_PROFILE_CHANGE_PASSWORD,
        USER_PROFILE_EDIT,
    ],
    [Role.STAFF]: [
        USER_LIST_STAFF,
        USER_PERMISSION_STAFF,
        USER_PROFILE_CHANGE_PASSWORD,
        USER_PROFILE_EDIT,
    ],
    [Role.AGENT]: [
        USER_PROFILE_CHANGE_PASSWORD,
        USER_PROFILE_EDIT,
    ]
};
export interface User {
    id: string;
    username: string;
    firstName: string;
    lastName: string;
    email: string;
    status: string;
    note?: string;
    createdAt: string;
    role?: Role;
    roleCode: RoleCode;
    roleId: number
    permissions: string[];
    ownerId?: number
    totalBalance?: number | string;
}



export const generalDateTimeFormat = 'DD/MM/YYYY - HH:mm';
export const generalDateFormat = 'DD/MM/YYYY';
export const fullThaiDateFormat = 'ddddที่ D MMMM YYYY';


export const SPACING_NONE = '0px';
export const SPACING_XS = '6px';
export const SPACING_SM = '12px';
export const SPACING_MD = '16px';
export const SPACING_LG = '24px';
export const SPACING_XL = '32px';
export const SPACING_XXL = '48px';


export const MOCK_PERF_KEY = [
    { id: 1, key: 'INHERIT_PERM_OWNER', allowedTorRoles: [] },
    { id: 2, key: 'INHERIT_PERM_STAFF', allowedTorRoles: [] },

    { id: 3, key: 'USER_CREATE_AGENT', allowedTorRoles: [2, 3] },
    { id: 4, key: 'USER_CREATE_OWNER', allowedTorRoles: [1] },
    { id: 5, key: 'USER_CREATE_STAFF', allowedTorRoles: [2, 3] },

    { id: 6, key: 'USER_DISABLE_AGENT', allowedTorRoles: [2, 3] },
    { id: 7, key: 'USER_DISABLE_OWNER', allowedTorRoles: [] },
    { id: 8, key: 'USER_DISABLE_STAFF', allowedTorRoles: [2, 3] },
    { id: 9, key: 'USER_DISABLE_SUPER_ADMIN', allowedTorRoles: [] },

    { id: 10, key: 'USER_EDIT_AGENT', allowedTorRoles: [2, 3] },
    { id: 11, key: 'USER_EDIT_OWNER', allowedTorRoles: [] },
    { id: 12, key: 'USER_EDIT_STAFF', allowedTorRoles: [2, 3] },

    { id: 13, key: 'USER_LIST', allowedTorRoles: [1] },
    { id: 14, key: 'USER_LIST_AGENT', allowedTorRoles: [2, 3] },
    { id: 15, key: 'USER_LIST_OWNER', allowedTorRoles: [1] },
    { id: 16, key: 'USER_LIST_STAFF', allowedTorRoles: [2, 3] },

    { id: 17, key: 'USER_PERMISSION_AGENT', allowedTorRoles: [2, 3] },
    { id: 18, key: 'USER_PERMISSION_OWNER', allowedTorRoles: [1] },
    { id: 19, key: 'USER_PERMISSION_STAFF', allowedTorRoles: [2, 3] },

    { id: 20, key: 'USER_PROFILE_CHANGE_PASSWORD', allowedTorRoles: [1, 2, 3, 4] },
    { id: 21, key: 'USER_PROFILE_EDIT', allowedTorRoles: [1, 2, 3, 4] },

    { id: 22, key: 'USER_VIEW_AGENT', allowedTorRoles: [2, 3] },
    { id: 23, key: 'USER_VIEW_OWNER', allowedTorRoles: [1] },
    { id: 24, key: 'USER_VIEW_STAFF', allowedTorRoles: [2, 3] },
    { id: 25, key: 'USER_VIEW_SUPER_ADMIN', allowedTorRoles: [1] },

    { id: 26, key: 'CREDIT_LIST_OWNER_CREDIT', allowedTorRoles: [1] },

    { id: 27, key: 'CREDIT_ADJUST_OWNER_CREDIT', allowedTorRoles: [1] },

    { id: 28, key: 'CREDIT_REQUEST_CREATE', allowedTorRoles: [2, 3] },

    { id: 29, key: 'CREDIT_REQUEST_LIST', allowedTorRoles: [2, 3] },

    { id: 30, key: 'CREDIT_REQUEST_APPROVE_REJECT', allowedTorRoles: [1] },

    { id: 31, key: 'CREDIT_TXN_LIST', allowedTorRoles: [1] }
]

export const defaultPagination = {
    limit: 10,
    page: 1,
    totalCount: 0,
    totalPages: 0
}

export const userDefaultCreateFormFields: AppFormData[] = [
    {
        value: '',
        name: 'roleCode', required: true, placeholder: 'Role', label: 'Role', inputType: 'select',
        choices: [
            { label: USER_ROLE_NAME[USER_ROLE.SUPER_ADMIN], value: USER_ROLE.SUPER_ADMIN, requiredPermission: `USER_CREATE_SUPER_ADMIN` },
            { label: USER_ROLE_NAME[USER_ROLE.OWNER], value: USER_ROLE.OWNER, requiredPermission: `USER_CREATE_OWNER` },
            { label: USER_ROLE_NAME[USER_ROLE.STAFF], value: USER_ROLE.STAFF, requiredPermission: `USER_CREATE_STAFF` },
            { label: USER_ROLE_NAME[USER_ROLE.AGENT], value: USER_ROLE.AGENT, requiredPermission: `USER_CREATE_AGENT` },
        ]
    },
    { value: '', name: 'firstName', required: false, placeholder: 'ชื่อ', label: 'ชื่อ' },
    { value: '', name: 'lastName', required: false, placeholder: 'นามสกุล', label: 'นามสกุล' },
    {
        value: '',
        name: 'phone',
        pattern: /^\d{10}$/,
        required: false,
        placeholder: 'เบอร์โทรศัพท์',
        label: 'เบอร์โทรศัพท์',
        textFieldProps: {
            autoComplete: 'phone',
            id: 'phone'

        }
    },
    { value: '', name: 'email', pattern: /\S+@\S+\.\S+/, required: false, placeholder: 'อีเมล', label: 'อีเมล' },
    { value: '', name: 'username', pattern: /\S+/, required: true, placeholder: 'ชื่อผู้ใช้', label: 'Username' },
    { value: '', name: 'password', required: true, placeholder: 'รหัสผ่านสมาชิก', label: 'รหัสผ่านสมาชิก', inputType: 'password', pattern: rxPassword },
];

export const tableContentConfig = {
    common: {
        align: 'left' as 'left',
        minWidth: 120,
        render: (value: string) => renderValueIfNotEmpty(value)
    },
    date: {
        align: 'left' as 'left',
        render: (value: string) => dayjs(value).format(generalDateFormat)
    },
    dateTime: {
        align: 'left' as 'left',
        render: (value: string) => dayjs(value).format(generalDateTimeFormat)
    },
    commonPrimary: {
        align: 'left' as 'left',
        minWidth: 120,
        render: (value: string) => renderValueIfNotEmpty(value),
        highlighten: true,
    },
    number: {
        align: 'right' as 'right',
        minWidth: 120,
        highlighten: true,
        render: (value: string | number) =>
            renderNumberWithCurrencyString(parseFloat(value ? `${value}` : `0`))
    },
    numberTrailingZero: {
        align: 'right' as 'right',
        minWidth: 120,
        highlighten: true,
        render: (value: string) => +value
    },
    action: {
        minWidth: 180,
        isSticky: true
    },
};


export const statusTableDisplayMap: { [key: string]: string } = {
    'APPROVED': 'อนุมัติแล้ว',
    'REJECTED': 'ปฏิเสธแล้ว',
    'REQUEST': 'รอดำเนินการ',
};

export const hoveredBgColor = '#00a2ff20';


export enum gameStatusEnum {
    none = '',
    opened = 'opened',
    closedAgent = 'closedAgent',
    closedStaff = 'closedStaff',
    checkedNumber = 'checkedNumber',
    finished = 'finished',
    cleared = 'cleared',
    cancelled = 'cancelled',
}

export const gameStateBgColorMap: Record<gameStatusEnum, 'success' | 'error' | 'warning' | 'info'> = {
    [gameStatusEnum.none]: 'success',
    [gameStatusEnum.opened]: 'success',
    [gameStatusEnum.closedAgent]: 'error',
    [gameStatusEnum.closedStaff]: 'error',
    [gameStatusEnum.checkedNumber]: 'info',
    [gameStatusEnum.finished]: 'warning',
    [gameStatusEnum.cleared]: 'info',
    [gameStatusEnum.cancelled]: 'error',
};


export enum gameTransitionType {
    init = '',
    due = 'due',
    closeAgent = 'closeAgent',
    closeStaff = 'closeStaff',
    checkNumber = 'checkNumber',
    closeBook = 'closeBook',
    archiveGame = 'archiveGame',
    reverseFinish = 'reverseFinish',
    cancel = 'cancel',
    cancelFromClosedAgent = 'cancelFromClosedAgent',
    cancelFromOpened = 'cancelFromOpened',
    cancelFromClosedStaff = 'cancelFromClosedStaff',
    reverseFinishFromClosedStaff = 'reverseFinishFromClosedStaff',
    reverseFinishFromCheckedNumber = 'reverseFinishFromCheckedNumber',
    reverseFinishFromFinished = 'reverseFinishFromFinished',
}

export const cancelIncludedStatus = [
    gameTransitionType.cancel,
    gameTransitionType.cancelFromClosedAgent,
    gameTransitionType.cancelFromOpened,
    gameTransitionType.cancelFromClosedStaff,
]
export const reverseIncludedStatus = [
    gameTransitionType.reverseFinishFromClosedStaff,
    gameTransitionType.reverseFinishFromCheckedNumber,
    gameTransitionType.reverseFinishFromFinished
]

export const gameStateDisplay: Record<gameStatusEnum, string> = {
    [gameStatusEnum.none]: 'เปิดรับ',
    [gameStatusEnum.opened]: 'เปิดรับ',
    [gameStatusEnum.closedAgent]: 'ปิดรับ Agent',
    [gameStatusEnum.closedStaff]: 'ปิดรับพนักงาน',
    [gameStatusEnum.checkedNumber]: 'ตรวจแล้ว',
    [gameStatusEnum.finished]: 'เสร็จ',
    [gameStatusEnum.cleared]: 'ล้างแล้ว',
    [gameStatusEnum.cancelled]: 'ยกเลิก',
};
export const gameTransitionDisplay: Record<gameTransitionType, string> = {
    [gameTransitionType.init]: "",
    [gameTransitionType.due]: "ถึงกำหนด",
    [gameTransitionType.closeAgent]: "ปิดรับ Agent",
    [gameTransitionType.closeStaff]: "ปิดรับพนักงาน",
    [gameTransitionType.checkNumber]: "ตรวจเลข",
    [gameTransitionType.closeBook]: "ปิดเล่ม",
    [gameTransitionType.archiveGame]: "ล้างเล่ม",
    [gameTransitionType.reverseFinish]: "ย้อนกลับไปแก้ไข",
    [gameTransitionType.cancel]: "ยกเลิก",
    [gameTransitionType.cancelFromClosedAgent]: "ยกเลิก",
    [gameTransitionType.cancelFromOpened]: "ยกเลิก",
    [gameTransitionType.cancelFromClosedStaff]: "ยกเลิก",
    [gameTransitionType.reverseFinishFromClosedStaff]: "ย้อนกลับไปแก้ไข",
    [gameTransitionType.reverseFinishFromCheckedNumber]: "ย้อนกลับไปแก้ไข",
    [gameTransitionType.reverseFinishFromFinished]: "ย้อนกลับไปแก้ไข"
};

type TransitionAction = {
    from: { label: string, status: gameStatusEnum };
    to: { label: string, status: gameStatusEnum }[];
    description?: string;
};

export const gameTransitionConfig: TransitionAction[] = [
    {
        from: { label: 'เปิดรับ', status: gameStatusEnum.none },
        to: [{ label: 'ปิดรับ Agent', status: gameStatusEnum.closedAgent }],
    },
    {
        from: { label: 'ปิดรับ Agent', status: gameStatusEnum.closedAgent },
        to: [
            { label: 'ปิดรับพนักงาน', status: gameStatusEnum.closedStaff },
        ],
    },
    {
        from: { label: 'ปิดรับพนักงาน', status: gameStatusEnum.closedStaff },
        to: [
            { label: 'ตรวจเลข', status: gameStatusEnum.checkedNumber },
            { label: 'ย้อนกลับไปแก้ไข', status: gameStatusEnum.closedAgent },
        ],
    },
    {
        from: { label: 'ตรวจแล้ว', status: gameStatusEnum.checkedNumber },
        to: [
            { label: 'ปิดเล่ม', status: gameStatusEnum.finished },
            { label: 'ย้อนกลับไปแก้ไข', status: gameStatusEnum.closedAgent },
        ],
    },
    {
        from: { label: 'เสร็จ', status: gameStatusEnum.finished },
        to: [
            { label: 'ย้อนกลับไปแก้ไข', status: gameStatusEnum.closedAgent },
        ],
    },
    {
        from: { label: 'ล้างแล้ว', status: gameStatusEnum.cleared },
        to: [],
    },
    {
        from: { label: 'ยกเลิก', status: gameStatusEnum.cancelled },
        to: [],
    },
];

type RateMapping = {
    [key: string]: { priceKey: string; percentKey: string };
};

// Define the mapping for displayeRateTH
export const rateMap: RateMapping = {
    "3 บน": { priceKey: "threeUpperStraightPrice", percentKey: "threeUpperStraightPercent" },
    "3 โต๊ด": { priceKey: "threeShufflePrice", percentKey: "threeShufflePercent" },
    "2 บน": { priceKey: "twoUpperStraightPrice", percentKey: "twoUpperStraightPercent" },
    "2 โต๊ด": { priceKey: "twoShufflePrice", percentKey: "twoShufflePercent" },
    "วิ่ง บน": { priceKey: "upperRunningPrice", percentKey: "upperRunningPercent" },
    "3 ล่าง": { priceKey: "threeLowerStraightPrice", percentKey: "threeLowerStraightPercent" },
    "2 ล่าง": { priceKey: "twoLowerStraightPrice", percentKey: "twoLowerStraightPercent" },
    "วิ่ง ล่าง": { priceKey: "lowerRunningPrice", percentKey: "lowerRunningPercent" },
    "ลอยแพ 4 ตัว": { priceKey: "fourFloatingPrice", percentKey: "fourFloatingPercent" },
    "ลอยแพ 5 ตัว": { priceKey: "fiveFloatingPrice", percentKey: "fiveFloatingPercent" },
};

export function rateDisplayTransform(input: Record<string, any>) {
    return Object.entries(rateMap).map(([displayeRateTH, keys]) => ({
        type: displayeRateTH,
        pay: input[keys.priceKey] || 0,
        discount: input[keys.percentKey] || 0,
    }));
}

export function getCancelTransitionFrom(currentState?: gameStatusEnum): gameTransitionType {
    if (currentState === gameStatusEnum.opened) return gameTransitionType.cancelFromOpened;
    else if (currentState === gameStatusEnum.closedAgent) return gameTransitionType.cancelFromClosedAgent;
    else if (currentState === gameStatusEnum.closedStaff) return gameTransitionType.cancelFromClosedStaff;
    return gameTransitionType.init;
}

// function getValidTransitions(currentState: gameStatusEnum): TransitionAction[] {
//     return gameTransitionConfig.filter(transition => transition.from === currentState);
// }